import { IGlobalBenchmarkProps } from '~/services/Dashboard/types'
import * as S from '../../styles'
import {
  Bar,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
  Legend,
  TooltipProps,
  ResponsiveContainer,
} from 'recharts'
import { theme } from '~/styles/theme'
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent'
import SquareRoundedIcon from '@mui/icons-material/SquareRounded'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useEffect, useState } from 'react'

export const ScrollableContainer = styled.div`
  overflow-y: auto;
  max-height: 300px;
`

const BenchmarkComponent = ({
  globalBenchmark = [],
}: {
  globalBenchmark: IGlobalBenchmarkProps[] | undefined
}) => {
  const { t } = useTranslation()
  const [anomalyType, setAnomalyType] = useState<IGlobalBenchmarkProps[]>([])
  const [totalHeight, setTotalHeight] = useState(300)
  const shouldShowInspectionBars = globalBenchmark[0]?.inspectionPercentage !== undefined

  useEffect(() => {
    let data = globalBenchmark.map((anomaly) => ({
      ...anomaly,
      name: t(anomaly.anomalyType),
    }))

    if (shouldShowInspectionBars) {
      data = data.filter(
        (anomaly) => anomaly.inspectionPercentage !== undefined && anomaly.inspectionPercentage > 0,
      )
    }

    setAnomalyType(data.slice().sort((a, b) => b.globalAveragePercentage - a.globalAveragePercentage))
    setTotalHeight(Math.max(300, data.length * 50))
  }, [globalBenchmark, t, shouldShowInspectionBars])

  return (
    <S.Benchmark>
      <h4>Benchmarking</h4>
      <p className="table-type">{t('Média em porcentagem')}</p>
      <ResponsiveContainer width="100%" height={totalHeight}>
        <S.CustomBarChart data={anomalyType} layout="vertical" barCategoryGap="20%" barSize={20}>
          <CartesianGrid />
          <XAxis type="number" />
          <YAxis dataKey="name" type="category" width={150} tickLine={false} tickMargin={10} />
          <Tooltip content={<CustomTooltip />} />
          <Bar dataKey="globalAveragePercentage" fill={theme.colors.orange} barSize={20} />
          {shouldShowInspectionBars && (
            <Bar dataKey="inspectionPercentage" fill={theme.colors.blue} barSize={20} />
          )}
          {shouldShowInspectionBars && (
            <Legend layout="vertical" verticalAlign="bottom" content={<RenderLegend />} />
          )}
        </S.CustomBarChart>
      </ResponsiveContainer>
    </S.Benchmark>
  )
}

export default BenchmarkComponent

const CustomTooltip = (props: TooltipProps<ValueType, NameType>) => {
  const { t } = useTranslation();
  const { active, payload, label } = props;

  if (active && payload && payload.length > 0) {
    return (
      <S.CustomTooltipWrapper>
        <h4>{label}</h4>
        {payload.map((data, index) => {
          const name = data.name;
          const isGlobalAverage = name === 'globalAveragePercentage';
          const translationKey = isGlobalAverage ? t('Solar Inspec') : t('Porcentagem média inspeção');
          const value = data.value as number;

          return (
            <h4 key={`${name}-${index}`}>
              {`${t(translationKey)}: `}
              <span style={{ color: data.color }}>{value.toFixed(2)}%</span>
            </h4>
          );
        })}
      </S.CustomTooltipWrapper>
    );
  }

  return null;
};



export const CustomizedAxisTick = (props: any) => {
  const { x, y, payload } = props

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={20} dy={16} textAnchor="middle" fill="#666" transform="rotate(-10)">
        {payload.value}
      </text>
    </g>
  )
}

export const RenderLegend = (props: any) => {
  const { payload } = props

  const showLegend = (value: string) => {
    switch (value) {
      case 'globalAveragePercentage':
        return 'Solar Inspec'
      case 'inspectionPercentage':
        return 'Porcentagem inspeção'
      default:
        return ''
    }
  }

  return (
    <S.LegendWrapper>
      {payload.map((entry: any) => (
        <S.LegendItem sx={{ color: entry?.payload?.fill }} key={entry.value}>
          <SquareRoundedIcon color="inherit" />
          <span className="mx-2" key={`item-${entry.value}`}>
            {showLegend(entry.value)}
          </span>
        </S.LegendItem>
      ))}
    </S.LegendWrapper>
  )
}
