import { Tab, Tabs } from '@mui/material'
import { Box } from '@mui/system'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import BaseLayout from '~/Layouts/BaseLayout'

import Aba from './Aba'

const Hardware = () => {
  const { t } = useTranslation()

  const tabComponents = [
    { id: 'modulos', label: t('Módulos'), component: <Aba tabNumber={0} /> },
    {
      id: 'inversores',
      label: t('Inversores'),
      component: <Aba tabNumber={1} />,
    },
    {
      id: 'trackers',
      label: t('Trackers'),
      component: <Aba tabNumber={2} />,
    },
  ]

  const firstAccessibleTab = 'modulos'

  const [activeTab, setActiveTab] = useState(firstAccessibleTab)

  useEffect(() => {
    setActiveTab(firstAccessibleTab)
  }, [firstAccessibleTab])

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue)
  }

  const renderTabContent = () => {
    const activeTabComponent = tabComponents.find((tab) => tab.id === activeTab)
    if (activeTabComponent) return activeTabComponent.component
    return null
  }

  return (
    <BaseLayout title={t('Equipamentos')}>
      <>
        <Box
          sx={{
            padding: '0 calc(10% + 20px)',
            borderBottom: '1px solid #e0e0e0',
            boxShadow: 'none',
            bgcolor: '#f5f5f5',
          }}
        >
          <Tabs
            value={activeTab}
            onChange={handleChangeTab}
            aria-label="configurações de abas"
            variant="standard"
            indicatorColor="primary"
            textColor="primary"
            sx={{
              '.MuiTab-root': {
                fontWeight: 'bold',
                color: 'black',
                '&.Mui-selected': {
                  color: '#1976d2',
                  backgroundColor: '#e3f2fd',
                },
              },
            }}
          >
            {tabComponents.map((tab) => (
              <Tab key={tab.id} label={tab.label} value={tab.id} />
            ))}
          </Tabs>
        </Box>
        <Box sx={{ padding: '20px', border: '1px solid #e0e0e0', borderTop: 'none' }}>
          {renderTabContent()}
        </Box>
      </>
    </BaseLayout>
  )
}

export default Hardware
