import { Backdrop, IconButton } from '@mui/material'
import { Swiper, SwiperSlide } from 'swiper/react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import uuid from 'react-uuid'
import CloseIcon from '@mui/icons-material/Close'
import DownloadIcon from '@mui/icons-material/Download'
import { Keyboard, Navigation } from 'swiper/modules'
import PrismaZoom from 'react-prismazoom'
import NoImagePlaceholder from '~/assets/images/no-image-placeholder.png'

interface InspectionStatusModalProps {
  open: boolean
  onClose: () => void
  thermalImageUrl: string
  rgbImageUrl: string
  complementaryImageUrls?: any[]
  modalCurrentImageIndex: number
}

const AnomalyImagesModal: React.FC<InspectionStatusModalProps> = ({
  open,
  onClose,
  thermalImageUrl,
  rgbImageUrl,
  complementaryImageUrls,
  modalCurrentImageIndex,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const zoomCounterRef = useRef<HTMLSpanElement>(null)
  const swiperRef = useRef(null)
  const swiper = (swiperRef.current as any)?.swiper

  const imagesList = [
    thermalImageUrl,
    rgbImageUrl,
    ...(complementaryImageUrls === undefined || complementaryImageUrls.length === 0
      ? []
      : complementaryImageUrls.map((imageObject: any) => imageObject.url)),
  ]

  const onZoomChange = useCallback((zoom: number) => {
    if (!zoomCounterRef.current) return
    zoomCounterRef.current.innerText = `${Math.round(zoom * 100)}%`
  }, [])

  const downloadImage = () => {
    const imageUrl = imagesList[currentSlide] || NoImagePlaceholder
    fetch(imageUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        return response.blob()
      })
      .then((blob) => {
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = 'image.png'
        link.click()
      })
      .catch((error) => {
        window.open(imageUrl, '_blank')
      })
  }

  useEffect(() => {
    if (!open) return
    setCurrentSlide(modalCurrentImageIndex)
    swiper?.slideTo(modalCurrentImageIndex, 0)
    onZoomChange(1)
  }, [open])

  return (
    <Backdrop
      open={open}
      sx={(theme) => ({ backgroundColor: 'rgba(0, 0, 0, 0.9)', zIndex: theme.zIndex.drawer + 1 })}
    >
      <div
        style={{
          position: 'absolute',
          top: '1rem',
          left: '1rem',
          color: 'white',
          zIndex: 10,
          display: 'flex',
          gap: '15px',
        }}
      >
        <p>
          {currentSlide + 1} / {imagesList.length}
        </p>
        <span ref={zoomCounterRef}>100%</span>
      </div>

      <div
        style={{
          position: 'absolute',
          top: '1rem',
          right: '1rem',
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          zIndex: 10,
        }}
      >
        <IconButton onClick={downloadImage}>
          <DownloadIcon sx={{ color: 'white' }} />
        </IconButton>
        <IconButton onClick={onClose}>
          <CloseIcon sx={{ color: 'white' }} />
        </IconButton>
      </div>

      <Swiper
        ref={swiperRef}
        modules={[Navigation, Keyboard]}
        keyboard={{
          enabled: true,
        }}
        navigation
        style={{
          width: '100%',
          height: '100vh',
        }}
        slidesPerView={1}
        spaceBetween={20}
        onSlideChange={({ activeIndex }: { activeIndex: number }) => {
          setCurrentSlide(activeIndex)
          onZoomChange(1)
        }}
        simulateTouch={false}
      >
        {imagesList.map((image) => (
          <SwiperSlide
            key={uuid()}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{ width: '80%', height: '80%', overflow: 'hidden', zIndex: 20, userSelect: 'none' }}
            >
              <PrismaZoom
                minZoom={1}
                maxZoom={8}
                onZoomChange={onZoomChange}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                <img
                  src={image || NoImagePlaceholder}
                  alt="image"
                  style={{
                    width: 'auto',
                    height: '100%',
                  }}
                />
              </PrismaZoom>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </Backdrop>
  )
}

export default AnomalyImagesModal
