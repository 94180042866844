import { getJson } from '../RequestService'
import { IRequestPark, IResponsePark } from './types'

const defaultRoot = 'park'

export const getAllParks = async (data: IRequestPark) => {
  let url = `${defaultRoot}?`
  if (data.filter) url += `Filter=${data.filter}`
  return await getJson<IResponsePark[]>(url)
}
