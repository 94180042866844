import { Modal, Box, Typography, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ToastNotification from '~/components/ToastNotification'
import { deleteReport } from '~/services/Reports'

interface IConfirmationModalProps {
  reportId: string | null
  handleClose: () => void
  refreshReports: () => void
}

const ConfirmationModal = ({ reportId, handleClose, refreshReports }: IConfirmationModalProps) => {
  const { t } = useTranslation()

  const handleDeleteReport = async (id: string) => {
    const response = await deleteReport({
      reportId: id,
    })

    if (response.success) {
      refreshReports()
      ToastNotification({
        id: 'success',
        type: 'success',
        message: t('Relatório excluído com sucesso!'),
      })
    } else {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Não foi possível excluir o relatório, tente novamente mais tarde.'),
        errorMessage: response.errorDetails,
        errors: response.errors,
      })
    }

    handleClose()
  }

  return (
    <Modal open={!!reportId} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6" component="h2">
          {t('Confirmar Exclusão')}
        </Typography>
        <Typography sx={{ mt: 2 }}>
          {t('Você tem certeza de que deseja excluir este relatório?')}
        </Typography>
        <Typography sx={{ color: 'red', fontWeight: 'bold' }}>
          {t('ATENÇÃO: Essa operação não pode ser desfeita.')}
        </Typography>
        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'end', gap: 2 }}>
          <Button variant="contained" color="error" onClick={() => handleDeleteReport(reportId ?? '')}>
            {t('Excluir')}
          </Button>
          <Button variant="outlined" onClick={handleClose}>
            {t('Cancelar')}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default ConfirmationModal
