import { deleteJson, getJson, postJson } from '../RequestService'
import {
  IReportHistoryArray,
  IRequestReport,
  IRequestReports,
  IResponseFilters,
  IResponseReport,
} from './types'

const defaultRoot = 'report'

export const getReports = async (data: IRequestReports) => {
  let url = `${defaultRoot}/history`
  url += `?PageNumber=${data?.page}&PageSize=${data?.pageSize}`
  if (data?.ReportType) url += `&ReportType=${data.ReportType}`
  if (data?.ReportName) url += `&ReportName=${data.ReportName}`
  if (data?.Username) url += `&Username=${data.Username}`
  if (data?.DateSince) url += `&DateSince=${data.DateSince}`
  if (data?.DateUntil) url += `&DateUntil=${data.DateUntil}`

  return await getJson<IReportHistoryArray>(url)
}

export const postReports = async (data: IRequestReport) => {
  let url = `${defaultRoot}/inspectionreport`
  return await postJson<unknown, IResponseReport>(url, data)
}

export const deleteReport = async (data: IRequestReport) => {
  let url = `${defaultRoot}`
  return await deleteJson<unknown, IResponseReport>(url, {
    ReportId: data.reportId,
  })
}

export const getReportFilters = async (InspectionId: string) => {
  let url = `${defaultRoot}/reportfilters?InspectionId=${InspectionId}`
  return await getJson<IResponseFilters>(url)
}
