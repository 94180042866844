import React, { useEffect, useRef, useState } from 'react'
import { Coordinates, EtapaProps, ImageGroup } from '../types'
import {
  Box,
  Button,
  IconButton,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import ReactMapGL, { MapRef, Marker, NavigationControl } from 'react-map-gl'
import { StyledTableRow } from '~/utils'
import exifr from 'exifr'
import ToastNotification from '~/components/ToastNotification'
import DeleteIcon from '@mui/icons-material/Delete'
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined'
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopy'
import RefreshIcon from '@mui/icons-material/Refresh'
import { getInspectionsUpload, postInspectionsImages, postUpdateStatus } from '~/services/Inspections'
import PlaceIcon from '@mui/icons-material/Place'
import { IResponseInspectionUpload } from '~/services/Inspections/types'
import html2canvas from 'html2canvas'
import LoadingComponent from '~/components/Loading/Loading'
import ErrorToast from '~/utils/toastErrorCatch'
import uuid from 'react-uuid'
import { getInspectionsAll, postFile } from '~/services/InspectionsFile'
import { useNavigate } from 'react-router-dom'

const EtapaView: React.FC<
  EtapaProps & {
    inspecId: string
    dadosInspecao?: IResponseInspectionUpload
    stepNumber: number
  }
> = ({ handleBack, handleNext, inspecId, dadosInspecao, stepNumber }) => {
  const [mapData, setMapData] = useState({
    longitude: -100,
    latitude: 40,
    zoom: 3,
    preserveDrawingBuffer: true,
  })

  const [imageGroups, setImageGroups] = useState<
    { coordinates: Coordinates; images: File[]; directoryKey: string }[]
  >([])
  const { t } = useTranslation()
  const navigate = useNavigate()
  const tableRef = useRef<HTMLDivElement>(null)
  const [groupIndex, setGroupIndex] = useState(0)
  const [sendStatus, setSendStatus] = useState<Map<string, string>>(() => {
    const initialStatus = new Map<string, string>()
    imageGroups.forEach((group) => {
      const key =
        stepNumber === 5 ? uuid() : `${group.coordinates.latitude}_${group.coordinates.longitude}`
      initialStatus.set(key, 'Aguardando')
    })
    return initialStatus
  })

  const [isLoading, setIsLoading] = useState(false)

  const [totalPairsSent, setTotalPairsSent] = useState(0)
  const [activeRequests, setActiveRequests] = useState(0)
  const maxConcurrentRequests = 3
  const [sendQueue, setSendQueue] = useState<ImageGroup[]>([])
  const [isSending, setIsSending] = useState(false)
  const [erroredCountGroups, setErroredCountGroups] = useState(0)

  let totalImages = 0
  let sentImages = 0
  if (stepNumber === 2 || stepNumber === 3) {
    totalImages = imageGroups.reduce((total, group) => total + group.images.length, 0)
    sentImages = totalPairsSent * 2
  } else {
    totalImages = imageGroups.length
    sentImages = totalPairsSent
  }
  const sendProgress = totalImages > 0 ? (sentImages / totalImages) * 100 : 0

  const [textLoading, setTextLoading] = useState('')

  const mapRef = useRef<MapRef | null>(null)

  const handleDownloadScreenshot = () => {
    if (!mapRef.current) {
      return
    }
    setIsLoading(true)
    setTextLoading('Capturando Imagem...')

    const mapElement = mapRef.current.getMap().getContainer()

    html2canvas(mapElement, {
      useCORS: true,
      logging: true,
      onclone: (document) => {},
    })
      .then((canvas) => {
        try {
          const dataURL = canvas.toDataURL('image/png')
          const link = document.createElement('a')
          link.href = dataURL
          link.download = 'map-screenshot.png'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        } catch (error) {
          console.error('Falha ao converter o canvas para URL:', error)
        }
      })
      .catch((error) => {
        console.error('Erro ao capturar screenshot:', error)
      })
      .finally(() => {
        setIsLoading(false)
        setTextLoading('')
      })
  }

  const headCells = [
    {
      label: t('Arquivos'),
      value: 'siteName',
    },
    {
      label: t('Status'),
      value: 'inspectedPower',
    },
    {
      label: t('Ações'),
      value: 'estimatedYearlyPowerLossInMegawatt',
    },
  ]

  const handleImagesChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (!files || files.length === 0) return

    setIsLoading(true)
    stepNumber === 5 ? setTextLoading('Carregando Arquivos...') : setTextLoading('Carregando Imagens...')

    const sortedFiles = Array.from(files)
      .filter((file) => {
        const validExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.pdf', '.dwg']
        return validExtensions.some((ext) => file.name.toLowerCase().endsWith(ext))
      })
      .sort((a, b) => a.name.localeCompare(b.name))
    let newGroups: any = []

    const createDataObject = async (file1: File, file2?: File) => {
      const existingFiles = imageGroups.flatMap((group) => group.images)
      if (existingFiles.some((existingFile) => existingFile.name === file1.name)) {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: `${t('Imagem duplicada encontrada:')} ${file1.name}`,
        })
        return null
      }

      let gpsData
      if (stepNumber !== 5) {
        gpsData = await exifr.gps(file1)
      } else {
        gpsData = { latitude: 1, longitude: 1 }
      }
      if (!gpsData?.latitude || !gpsData?.longitude) return null
      newGroups.push({
        coordinates: { latitude: gpsData.latitude, longitude: gpsData.longitude },
        images: file2 ? [file1, file2] : [file1],
        directoryKey: `group-${groupIndex + newGroups.length}`,
      })
    }

    if (stepNumber === 2 || stepNumber === 3) {
      for (let i = 0; i < sortedFiles.length; i += 2) {
        const file1 = sortedFiles[i]
        const file2 = sortedFiles[i + 1] || null

        if (file2) {
          await createDataObject(file1, file2)
        }
      }
    } else {
      for (const file of sortedFiles) {
        await createDataObject(file)
      }
    }

    setImageGroups([...imageGroups, ...newGroups])
    setGroupIndex((prevIndex) => prevIndex + newGroups.length)
    setIsLoading(false)
    setTextLoading('')

    event.target.value = ''
  }

  const [unpairedImage, setUnpairedImage] = useState<File | null>(null)

  const handleDirectoryChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (!files || files.length === 0) return

    setIsLoading(true)
    stepNumber === 5 ? setTextLoading('Carregando Arquivos...') : setTextLoading('Carregando Imagens...')

    const sortedFiles = Array.from(files)
      .filter((file) => {
        const validExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.pdf', '.dwg']
        return validExtensions.some((ext) => file.name.toLowerCase().endsWith(ext))
      })
      .sort((a, b) => {
        if (a.webkitRelativePath < b.webkitRelativePath) return -1
        if (a.webkitRelativePath > b.webkitRelativePath) return 1
        return a.name.localeCompare(b.name)
      })

    const newUnpaired = unpairedImage ? [unpairedImage, ...sortedFiles] : sortedFiles
    const { newGroups, leftoverImage } = await processImageGroups(newUnpaired)

    setImageGroups((prevGroups) => [...prevGroups, ...newGroups])
    setUnpairedImage(leftoverImage)
    setGroupIndex((prevIndex) => prevIndex + newGroups.length)
    setIsLoading(false)
    setTextLoading('')

    event.target.value = ''
  }

  const processImageGroups = async (
    files: File[],
  ): Promise<{ newGroups: ImageGroup[]; leftoverImage: File | null }> => {
    const newGroups: ImageGroup[] = []
    let leftoverImage: File | null = null

    if (stepNumber === 2 || stepNumber === 3) {
      for (let i = 0; i < files.length; i += 2) {
        const file1 = files[i]
        const file2 = files[i + 1] || null

        if (file1 && file2) {
          const gpsData1 = await exifr.gps(file1)
          const gpsData2 = await exifr.gps(file2)

          if (gpsData1 && gpsData2) {
            const coordinates = {
              latitude: (gpsData1.latitude + gpsData2.latitude) / 2,
              longitude: (gpsData1.longitude + gpsData2.longitude) / 2,
            }

            const alreadyUploaded = dadosInspecao?.some((upload) => {
              const latDiff = Math.abs(upload.latitude - coordinates.latitude) < 0.0001
              const lonDiff = Math.abs(upload.longitude - coordinates.longitude) < 0.0001
              const sizeMatch1 = upload.rgbSize === file1.size || upload.thermalSize === file1.size
              const sizeMatch2 = upload.rgbSize === file2.size || upload.thermalSize === file2.size
              return latDiff && lonDiff && (sizeMatch1 || sizeMatch2)
            })

            const alreadyInGroups = imageGroups.some((group) =>
              group.images.some((img) => img.name === file1.name || img.name === file2.name),
            )

            if (alreadyUploaded || alreadyInGroups) {
              ToastNotification({
                id: 'error',
                type: 'error',
                message: `${t('Imagem duplicada encontrada:')} ${file1.name} ${t('ou')} ${file2.name}`,
              })
              continue
            }

            newGroups.push({
              directoryKey: `group-${groupIndex + newGroups.length}`,
              images: [file1, file2],
              coordinates,
            })
          } else {
            leftoverImage = file2
          }
        } else if (file1) {
          leftoverImage = file1
        }
      }
    } else {
      for (const file of files) {
        if (file) {
          const gpsData = await exifr.gps(file)

          if (gpsData) {
            const coordinates = {
              latitude: gpsData.latitude,
              longitude: gpsData.longitude,
            }

            const alreadyUploaded = dadosInspecao?.some((upload) => {
              const latDiff = Math.abs(upload.latitude - coordinates.latitude) < 0.0001
              const lonDiff = Math.abs(upload.longitude - coordinates.longitude) < 0.0001
              const sizeMatch = upload.rgbSize === file.size || upload.thermalSize === file.size
              return latDiff && lonDiff && sizeMatch
            })

            const alreadyInGroups = imageGroups.some((group) =>
              group.images.some((img) => img.name === file.name),
            )

            if (alreadyUploaded || alreadyInGroups) {
              ToastNotification({
                id: 'error',
                type: 'error',
                message: `${t('Imagem duplicada encontrada:')} ${file.name}`,
              })
              continue
            }

            newGroups.push({
              directoryKey: `group-${groupIndex + newGroups.length}`,
              images: [file],
              coordinates,
            })
          } else {
            leftoverImage = file
          }
        }
      }
    }

    return { newGroups, leftoverImage }
  }

  const handleDeleteImage = (groupKey: string) => {
    setImageGroups((prevGroups) => prevGroups.filter((group) => group.directoryKey !== groupKey))
    setSendStatus((prevStatus) => {
      if (prevStatus.has(groupKey)) {
        const newStatus = new Map(prevStatus)
        newStatus.delete(groupKey)
        return newStatus
      }
      return prevStatus
    })
  }

  const updateStage = async () => {
    try {
      await postUpdateStatus({
        inspectionId: inspecId,
        isUploadingNewImages: true,
      })
    } catch (error) {
      return <ErrorToast message={t('Não foi possível avançar a etapa, tente novamente mais tarde.')} />
    }
  }

  useEffect(() => {
    if (sendProgress === 100 && (stepNumber === 2 || stepNumber === 3)) updateStage()
  }, [sendProgress])

  useEffect(() => {
    if (stepNumber === 5) return
    if (imageGroups.length === 1) {
      const firstGroup = imageGroups[0].coordinates
      setMapData({
        longitude: firstGroup.longitude,
        latitude: firstGroup.latitude,
        zoom: 25,
        preserveDrawingBuffer: true,
      })
    } else if (imageGroups.length > 1) {
      const firstGroup = imageGroups[0].coordinates
      setMapData({
        longitude: firstGroup.longitude,
        latitude: firstGroup.latitude,
        zoom: 17,
        preserveDrawingBuffer: true,
      })
    }

    const newStatus = new Map(sendStatus)

    imageGroups.forEach((group) => {
      const key = group.directoryKey
      if (!newStatus.has(key)) {
        newStatus.set(key, 'Aguardando')
      }
    })

    newStatus.forEach((_, key) => {
      if (!imageGroups.some((group) => group.directoryKey === key)) {
        newStatus.delete(key)
      }
    })

    setSendStatus(newStatus)
    handleScrollToBottom()
  }, [imageGroups])

  const loadUploadedImages = async () => {
    let response: any = null
    if (stepNumber === 2 || stepNumber === 3) {
      response = await getInspectionsUpload({ InspectionId: inspecId, PairType: stepNumber - 1 })
    } else {
      response = await getInspectionsAll({ InspectionId: inspecId, FileType: stepNumber - 2 })
    }

    let newGroups: any[] = []
    let newStatus = new Map()

    response.data.forEach((upload: any, index: any) => {
      newGroups.push({
        directoryKey: `group-${index}`,
        images:
          stepNumber === 2 || stepNumber === 3
            ? [
                {
                  name: upload.rgbFilename ?? 'NULL',
                  size: upload.rgbSize,
                },
                {
                  name: upload.thermalFilename ?? 'NULL',
                  size: upload.thermalSize,
                },
              ]
            : [
                {
                  name: upload.originalFileName ?? 'NULL',
                  size: upload.length,
                },
              ],
        coordinates: {
          latitude: stepNumber === 2 || stepNumber === 3 ? upload.latitude : 1,
          longitude: stepNumber === 2 || stepNumber === 3 ? upload.longitude : 1,
        },
      })

      newStatus.set(`group-${index}`, 'Enviado')
    })

    setImageGroups(newGroups)
    setSendStatus(newStatus)
    setTotalPairsSent(response.data.length)
    setGroupIndex(newGroups.length)
  }

  useEffect(() => {
    if (stepNumber === 0 || stepNumber === 1) return

    setImageGroups([])
    setTotalPairsSent(0)
    loadUploadedImages()
    setMapData({
      longitude: -100,
      latitude: 40,
      zoom: 3,
      preserveDrawingBuffer: true,
    })
  }, [stepNumber])

  const prepareAndSendImage = async (group: ImageGroup, inspecId: string) => {
    const key = group.directoryKey
    let thermalImage: File | null = null
    let rgbImage: File | null = null
    const image = group.images[0]

    group.images.forEach((image) => {
      if (thermalImage === null || image.size < thermalImage.size) {
        if (thermalImage !== null) {
          rgbImage = thermalImage
        }
        thermalImage = image
      } else if (rgbImage === null || image.size > rgbImage.size) {
        rgbImage = image
      }
    })

    const postImages = async (formData: FormData) => {
      try {
        setSendStatus((prev) => new Map(prev).set(group.directoryKey, 'Enviando'))
        stepNumber === 2 || stepNumber === 3
          ? await postInspectionsImages(formData)
          : await postFile(formData)
        setTotalPairsSent((prevTotal) => prevTotal + 1)
        setSendStatus((prev) => new Map(prev).set(group.directoryKey, 'Enviado'))
      } catch (error) {
        setSendStatus((prev) => new Map(prev).set(key, 'Erro'))
      } finally {
        setActiveRequests((current) => current - 1)
      }
    }

    const formData = new FormData()
    formData.append('InspectionId', inspecId)
    if (thermalImage && rgbImage) {
      formData.append('ThermalFile', thermalImage)
      formData.append('RGBFile', rgbImage)
      formData.append('ThermalFilePath', (thermalImage as any).webkitRelativePath)
      formData.append('RGBFilePath', (rgbImage as any).webkitRelativePath)
      stepNumber === 2 && formData.append('PairType', '1')
      stepNumber === 3 && formData.append('PairType', '2')
      await postImages(formData)
    } else if (image) {
      formData.append('File', image)
      formData.append('ValidateSubparksNames', 'false')
      stepNumber === 4 && formData.append('FileType', '2')
      stepNumber === 5 && formData.append('FileType', '3')
      await postImages(formData)
    } else {
      setActiveRequests((current) => current - 1)
    }
  }

  const enqueueSendImage = (group: ImageGroup) => {
    const currentStatus = sendStatus.get(group.directoryKey)
    if (currentStatus !== 'Enviado') {
      setSendQueue((prevQueue) => [...prevQueue, group])
      if (!isSending) {
        sendImages()
      }
    }
  }

  const sendImages = async () => {
    setIsSending(true)

    while (activeRequests < maxConcurrentRequests && sendQueue.length > 0) {
      const groupsToSend = sendQueue.splice(0, maxConcurrentRequests - activeRequests)
      setActiveRequests((current) => current + groupsToSend.length)

      await Promise.all(groupsToSend.map((group) => prepareAndSendImage(group, inspecId)))
      setActiveRequests((current) => current - groupsToSend.length)
    }

    setIsSending(false)
  }

  useEffect(() => {
    if (!isSending && sendQueue.length > 0) {
      sendImages()
    }

    if (isSending == false) {
      setImageGroups((prevGroups) => {
        const erroredGroups = prevGroups.filter((group) => sendStatus.get(group.directoryKey) === 'Erro')
        setErroredCountGroups(erroredGroups.length)
        const otherGroups = prevGroups.filter((group) => sendStatus.get(group.directoryKey) !== 'Erro')
        return [...otherGroups, ...erroredGroups]
      })
    }
  }, [sendQueue, isSending])

  const handleDrop = async (event: any) => {
    event.preventDefault()
    const dataTransfer = event.dataTransfer

    if (dataTransfer.items && dataTransfer.items.length > 0) {
      const item = dataTransfer.items[0]
      if (item.kind === 'file') {
        const files = dataTransfer.files

        const entry = item.webkitGetAsEntry?.()
        if (entry?.isDirectory) {
          const fileList: File[] = []
          await processDirectory(entry, fileList)

          const directoryEvent: any = {
            target: {
              files: fileList,
            },
          }
          handleDirectoryChange(directoryEvent)
        } else {
          const fileEvent: any = {
            target: {
              files,
            },
          }
          handleImagesChange(fileEvent)
        }
      }
    }
  }

  const processDirectory = async (entry: any, fileList: File[]) => {
    const dirReader = entry.createReader()
    return new Promise<void>((resolve) => {
      const readEntries = () => {
        dirReader.readEntries(async (entries: any[]) => {
          if (entries.length === 0) {
            resolve()
            return
          }

          for (const entry of entries) {
            if (entry.isFile) {
              const file = await new Promise<File>((resolve) => {
                entry.file(resolve)
              })
              fileList.push(file)
            } else if (entry.isDirectory) {
              await processDirectory(entry, fileList)
            }
          }

          readEntries()
        })
      }

      readEntries()
    })
  }

  const handleScrollToBottom = () => {
    if (tableRef.current) {
      tableRef.current.scrollTop = tableRef.current.scrollHeight
    }
  }

  return (
    <>
      {stepNumber === 5 && (
        <LoadingComponent loading={isLoading} text={textLoading ?? 'Carregando Arquivos...'} />
      )}
      {stepNumber !== 5 && (
        <LoadingComponent loading={isLoading} text={textLoading ?? 'Carregando Imagens...'} />
      )}
      <Stack
        sx={{
          margin: '24px 5%',
          display: 'flex',
          minHeight: '60vh',
          flexDirection: 'column',
          gap: '15px',
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '16px',
          border: '1px solid #C4C4C4',
        }}
      >
        <Typography variant="h6" fontWeight="700" align="center">
          {stepNumber === 2 && 'Imagens RGB e IR (90 graus)'}
          {stepNumber === 3 && 'Imagens RGB e IR (45 graus)'}
          {stepNumber === 4 && 'Ortomosaico em RGB'}
          {stepNumber === 5 && 'Diagrama Elétrico (DWG ou PDF)'}
        </Typography>
        <Stack direction={'row'} sx={{ flexGrow: 1, gap: '30px', height: '70vh' }}>
          {stepNumber !== 5 && (
            <Stack flex={1} sx={{ flexBasis: '45%', height: 'auto', backgroundColor: 'transparent' }}>
              <ReactMapGL
                id={uuid()}
                {...mapData}
                onMove={(evt) => {
                  setMapData((prevData) => ({
                    ...prevData,
                    longitude: evt.viewState.longitude,
                    latitude: evt.viewState.latitude,
                    zoom: evt.viewState.zoom,
                  }))
                }}
                style={{
                  width: '100%',
                  height: '100%',
                  position: 'relative',
                }}
                mapStyle={'mapbox://styles/mapbox/satellite-v9'}
                mapboxAccessToken="pk.eyJ1IjoiYW5kcmV5Y2FzZXR0YSIsImEiOiJja2c3MHN1YnowMnQ4MnZvOXRhcWFyZnp2In0.DaCc8a68xzWJpBK3cdU8jw"
                ref={mapRef}
              >
                {imageGroups.map((group) => (
                  <Marker
                    key={`${group.coordinates.latitude}_${group.coordinates.longitude}`}
                    longitude={group.coordinates.longitude}
                    latitude={group.coordinates.latitude}
                    style={{ zIndex: 9999999999 }}
                  >
                    <PlaceIcon style={{ fontSize: '15px', color: 'red', zIndex: 9999999999 }} />
                  </Marker>
                ))}
                <NavigationControl style={{ right: 10, top: 10, zIndex: 999999999999 }} />
              </ReactMapGL>
            </Stack>
          )}

          <Stack
            flex={2}
            sx={{
              flexBasis: '55%',
              border: '1px solid #C4C4C4',
            }}
          >
            <TableContainer
              ref={tableRef}
              sx={{ height: '100%' }}
              onDrop={handleDrop}
              onDragOver={(event) => event.preventDefault()}
            >
              <Table sx={{ width: '100%' }} aria-labelledby="tableTitle" size="medium">
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell, i) => (
                      <TableCell
                        key={headCell.value}
                        align={i === 0 || i === 4 ? 'left' : 'right'}
                        padding="normal"
                        sx={{
                          fontWeight: 'bold',
                          backgroundColor: '#dbe1ee',
                          borderStartEndRadius: i === headCells.length - 1 ? '4px' : '0',
                          borderStartStartRadius: i === 0 ? '4px' : '0',
                        }}
                      >
                        {headCell.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {imageGroups.length > 0 ? (
                    imageGroups.map((group) => {
                      const key = group.directoryKey
                      const currentStatus = sendStatus.get(key) ?? 'Aguardando'

                      return (
                        <StyledTableRow
                          id={key}
                          hover
                          tabIndex={-1}
                          key={key}
                          sx={{ cursor: 'pointer' }}
                        >
                          <TableCell align="left">
                            {group.images.map((file) => (
                              <Stack key={file.name} direction="row" alignItems="center" spacing={1}>
                                {stepNumber === 5 && <FileCopyOutlinedIcon />}
                                {stepNumber !== 5 && <PhotoOutlinedIcon />}
                                <Typography>{file.name}</Typography>
                              </Stack>
                            ))}
                          </TableCell>
                          <TableCell align="center">
                            <Stack direction="row" sx={{ alignItems: 'center', gap: 1 }}>
                              <Box sx={{ flexGrow: 1 }}>
                                <Typography variant="body2" sx={{ textAlign: 'right' }}>
                                  {currentStatus}
                                </Typography>
                              </Box>
                            </Stack>
                          </TableCell>
                          <TableCell align="right">
                            {group.images.length === 2 &&
                              (currentStatus === 'Aguardando' || currentStatus === 'Erro') && (
                                <IconButton
                                  onClick={() => prepareAndSendImage(group, inspecId)}
                                  style={{ color: '#0C4A7D' }}
                                >
                                  <RefreshIcon />
                                </IconButton>
                              )}
                            {(currentStatus == 'Aguardando' || currentStatus == 'Erro') && (
                              <IconButton onClick={() => handleDeleteImage(key)} color="error">
                                <DeleteIcon />
                              </IconButton>
                            )}
                          </TableCell>
                        </StyledTableRow>
                      )
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={headCells.length} align="center">
                        {stepNumber === 5
                          ? t('Nenhuma imagem encontrada. Por favor, carregue uma pasta de arquivos.')
                          : t('Nenhuma imagem encontrada. Por favor, carregue uma pasta de imagens.')}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ padding: '0.25rem', borderTop: '2px solid #e0e0e0', mt: 2 }}
            >
              <Stack
                sx={{
                  width: '25%',
                  marginLeft: '0.50rem',
                  borderRight: '1px solid #e0e0e0',
                  pr: 2,
                  alignItems: 'center',
                }}
              >
                <Typography variant="subtitle2">
                  {stepNumber == 5 ? t('Arquivos Selecionados') : t('Imagens Selecionadas')}
                </Typography>
                <Typography variant="subtitle2">
                  {imageGroups.reduce((total, group) => total + group.images.length, 0)}
                </Typography>
              </Stack>
              <Stack
                sx={{
                  width: '25%',
                  marginLeft: '0.50rem',
                  borderRight: '1px solid #e0e0e0',
                  pr: 2,
                  alignItems: 'center',
                }}
              >
                <Typography variant="subtitle2">
                  {stepNumber == 5 ? t('Arquivos Enviados') : t('Imagens Enviadas')}
                </Typography>
                <Typography variant="subtitle2">
                  {stepNumber == 2 || stepNumber == 3 ? totalPairsSent * 2 : totalPairsSent}
                </Typography>
              </Stack>
              <Stack
                sx={{
                  width: '25%',
                  marginLeft: '0.50rem',
                  borderRight: '1px solid #e0e0e0',
                  pr: 2,
                  alignItems: 'center',
                }}
              >
                <Typography variant="subtitle2">
                  {stepNumber == 5 ? t('Arquivos Falhados') : t('Imagens Falhadas')}
                </Typography>
                <Typography variant="subtitle2">
                  {stepNumber == 2 || stepNumber == 3 ? erroredCountGroups * 2 : erroredCountGroups}
                </Typography>
              </Stack>
              <Stack
                sx={{
                  width: '25%',
                  marginLeft: '0.50rem',
                  alignItems: 'center',
                }}
              >
                <Typography variant="subtitle2">{t('Progresso Total')}</Typography>
                <Stack
                  direction="row"
                  sx={{ width: '100%', justifyContent: 'center', alignItems: 'center', gap: 2 }}
                >
                  <LinearProgress
                    variant="determinate"
                    value={sendProgress}
                    sx={{ width: '70%', height: 10, borderRadius: 5 }}
                  />
                  <Typography variant="subtitle2">{sendProgress.toFixed(0)}%</Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>

        <Stack
          width={'100%'}
          gap={2}
          flexDirection="row"
          alignItems="flex-end"
          justifyContent="space-between"
        >
          <Button variant="outlined" onClick={handleBack}>
            {t('Voltar')}
          </Button>
          <Stack
            width={'100%'}
            gap={2}
            flexDirection="row"
            alignItems="flex-end"
            justifyContent="flex-end"
          >
            {stepNumber !== 5 && (
              <Box>
                <Tooltip
                  title={t(
                    'Ajuste o mapa de uma forma que seja possível a captura de todos os marcadores',
                  )}
                >
                  <Button
                    onClick={() => {
                      setIsLoading(true)
                      handleDownloadScreenshot()
                    }}
                    variant="contained"
                    color="primary"
                  >
                    Captura do Mapa
                  </Button>
                </Tooltip>
              </Box>
            )}

            <input
              type="file"
              multiple
              webkitdirectory=""
              onChange={handleDirectoryChange}
              accept={stepNumber == 5 ? '*' : 'image/*'}
              style={{ display: 'none' }}
              id="file-directory"
            />
            <label htmlFor="file-directory">
              <Button variant="contained" component="span" fullWidth>
                {t('Selecionar Pasta')}
              </Button>
            </label>

            <input
              type="file"
              multiple
              onChange={handleImagesChange}
              accept={stepNumber == 5 ? '*' : 'image/*'}
              style={{ display: 'none' }}
              id="file-input"
            />
            <label htmlFor="file-input">
              <Button variant="contained" component="span" fullWidth>
                {stepNumber == 5 ? t('Selecionar Arquivos') : t('Selecionar Imagens')}
              </Button>
            </label>

            {imageGroups.some((group) => sendStatus.get(group.directoryKey) === 'Erro') && (
              <Button
                variant="contained"
                onClick={() =>
                  imageGroups
                    .filter((group) => sendStatus.get(group.directoryKey) === 'Erro')
                    .forEach((group) => enqueueSendImage(group))
                }
                disabled={imageGroups.some((group) => sendStatus.get(group.directoryKey) === 'Enviando')}
                sx={{
                  backgroundColor: '#FFA500',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: '#FFC107',
                  },
                }}
              >
                {t('Re-enviar Falhados')}
              </Button>
            )}
            {imageGroups.length <= 0 || sendProgress === 100 ? (
              <Button
                variant="contained"
                disabled={isSending}
                onClick={() => {
                  if (stepNumber == 5) {
                    navigate(-1)
                    return
                  }
                  handleNext()
                }}
              >
                {stepNumber == 5 ? t('Concluir') : t('Avançar')}
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => imageGroups.forEach((group) => enqueueSendImage(group))}
                disabled={imageGroups.some((group) => sendStatus.get(group.directoryKey) === 'Enviando')}
                sx={{
                  backgroundColor: '#3F8A05',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: '#48a005',
                  },
                }}
              >
                {t('Enviar')}
              </Button>
            )}
          </Stack>
        </Stack>
      </Stack>
    </>
  )
}

export default EtapaView
