import styled from 'styled-components/macro'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { Box, TableCell as MUITableCell } from '@mui/material'

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const ModalDelete = styled.div`
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translate(-50%, -5%);
  background-color: #fafafa;
  min-width: 450px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
  z-index: 110;

  .modal__content {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .btn {
    font-size: 16px;
    padding: 5px 20px;
    border-radius: 5px;

    &:hover {
      filter: saturate(60%);
    }
  }

  .wrapper-btns {
    align-self: flex-end;
    gap: 10px;
    display: flex;
  }

  .btn-cancelar {
    background-color: #fd5757;
    text-transform: uppercase;
    font-weight: bold;
    color: #fff;
  }
  .btn-confirmar {
    background-color: #00a732;
    text-transform: uppercase;
    font-weight: bold;
    color: #fff;
  }
`

export const OverlayDelete = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 100;
  backdrop-filter: blur(5px);
  background-color: #07070767;
`

export const Search = styled.div`
  padding: 40px 0;
`

export const Container = styled.div`
  margin: 0 10%;
`

export const Form = styled.form`
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
`

export const SearchFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 900px) {
    flex-direction: column;
  }

  input {
    width: 418px;
    height: 52px;

    background: #ffffff;
    border-radius: 5px;
    border: 0;
    padding: 0 7px;
    transition: 0.5s;

    &:focus {
      border: 1px solid ${(props) => props.theme.colors.primary};
    }

    @media (max-width: 900px) {
      width: 100%;
      margin-bottom: 10px;
    }
  }

  select {
    width: 200px;
    height: 52px;
    transition: 0.5s;
    border: 1px solid var(--bg-login);
    background: #ffffff;
    border-radius: 5px;
    border: 0;

    color: var(--color-text);

    &:focus {
      border: 1px solid ${(props) => props.theme.colors.primary};
    }

    @media (max-width: 900px) {
      width: 100%;
    }
  }
`

export const ContainerSites2 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  justify-items: flex-start;
  align-items: flex-start;

  padding: 20px 20px 0 20px;
  border-radius: 5px;

  input {
    width: 100%;
    height: 40px;
    padding: 0 7px;
    background: #eff3f9;
    border-radius: 10px;
    border: 0;
  }

  label {
    text-align: left;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    color: #5a5c5e;
  }
  select {
    width: 100%;
    height: 40px;
    padding: 0 7px;
    background: #eff3f9;
    border-radius: 10px;
    border: 0;
    margin: 10px 0;
  }

  div {
    input[type='file'] {
      display: none;
    }

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    label {
      width: 100%;
      height: 40px;
      background: #eff3f9;
      border-radius: 10px;
      color: #5a5c5e;
      text-align: center;
      display: block;
      cursor: pointer;
    }
  }

  .containerDragAndDrop div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* button:last-child {
    margin: 20px 0;
    width: 100%;
    height: 40px;
    border-radius: 10px;
    border: 0;
    background: var(--color-orange);
    color: white;
  } */
  .wrapperBtn {
    display: flex;
    gap: 10px;
  }

  .btn-confirm {
    /* margin: 20px 0; */
    width: 100%;
    height: 40px;
    border-radius: 10px;
    border: 0;
    background: #2ab300;
    color: white;
  }
  .btn-cancel {
    /* margin: 20px 0; */
    width: 100%;
    height: 40px;
    border-radius: 10px;
    border: 0;
    background: #ff3030;
    color: white;
  }

  .flex-btns {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 800px) {
      flex-direction: column;
    }

    button {
      width: 258px;
      height: 40px;
      background: ${(props) => props.theme.colors.primary};
      border-radius: 5px;
      color: white;
      border: 0;

      @media (max-width: 800px) {
        margin-top: 10px;
        width: 100%;
        padding: 0 4%;
      }
    }
  }
`

export const ImageInput = styled.div`
  display: flex;

  gap: 10px;

  .inputs-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .image-upload {
    width: 290px;
  }

  .image-upload > input {
    display: none;
    align-items: center;
    justify-content: center;
  }
  .inputImage {
    display: block;
    width: 100%;
    border-radius: 5px;
    object-fit: cover;
  }
`

export const ContainerSites = styled.div`
  .flex-btns {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 800px) {
      flex-direction: column;
    }

    input {
      width: 100%;
    }
    button {
      width: 258px;
      height: 40px;
      margin: 10px 0 30px 0;
      background: ${(props) => props.theme.colors.primary};
      border-radius: 5px;
      color: white;
      border: 0;

      @media (max-width: 800px) {
        margin-top: 10px;
        width: 100%;
        padding: 0 4%;
      }
    }
    a {
      width: 258px;
      height: 40px;
      margin: 10px 0 30px 0;
      background: ${(props) => props.theme.colors.primary};
      border-radius: 5px;
      color: white;
      border: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 800px) {
        margin-top: 10px;
        width: 100%;
        padding: 0 4%;
      }
    }
  }
`

export const SitesGrid = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(6, 1fr);

  @media (max-width: 2100px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media (max-width: 1800px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 1500px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 1100px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

export const ContentBoxSolar = styled.div`
  width: 100%;
  background: white;
  height: 100%;
  border-radius: 10px;

  .flex-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .contentData {
    padding: 0 20px;

    h6 {
      font-size: 20px;
    }

    button {
      width: 135px;
    }

    .flex-content {
      h6 {
        font-size: 14px;
        color: var(--color-text);
        padding: 10px 0;
      }

      p {
        font-size: 12px;
      }
    }
  }

  img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 10px 10px 0px 0px;
  }

  .noPhotography {
    width: 100%;
    height: 45%;
    padding: 25%;
    opacity: 0.6;
    border-radius: 10px 10px 0px 0px;
  }
`

export const WrapperInputs = styled.div`
  display: flex;
  gap: 10px;

  input {
    text-align: center;
  }
`

export const WrapperInputsLabel = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;

  div,
  label {
    background-color: transparent !important;
  }

  label {
    text-align: left;
  }
`
export const LinkEdit = styled.span`
  background: none;
  border: 2px solid transparent;
`
export const DivDiagrama = styled.div`
  display: flex;
  gap: 10px;

  input {
    text-align: center;
  }
`

export const NavDash = styled.div`
  background: var(--bg-login);
  padding: 20px;
`

export const ContainerNav = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-left: 10px;
    width: 96px;
    height: 23px;
    font-size: 14px;
    background: rgba(248, 151, 34, 0.1);
    border-radius: 5px;

    display: flex;
    align-items: center;
    justify-content: center;

    color: #b6b6b6;
  }
  @media (min-width: 600px) {
    margin: 0 5%;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.colors.primary} !important;
  }
`

export const ContainerFilter = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
`

export const Filter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  label {
    white-space: nowrap;
    margin-left: 8px;
    color: ${(props) => props.theme.colors.primary};
  }
`
export const ContainerSearch = styled.div`
  width: 100%;
`
export const ContainerSearchList = styled.div`
  width: 100%;
`

export const ContainerButton = styled.div`
  justify-content: center;
  align-items: center;

  select {
    width: 200px;
    height: 52px;
    transition: 0.5s;
    border: 1px solid var(--bg-login);
    background: #ffffff;
    border-radius: 5px;
    border: 0;

    color: var(--color-text);

    &:focus {
      border: 1px solid ${(props) => props.theme.colors.primary};
    }

    @media (max-width: 900px) {
      width: 100%;
    }
  }
`

export const Pages = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 20px 0;
`

export const StyleBox = styled.div`
  position: absolute;
  width: 500px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 12px 16px;
  background-color: white;
  border: 1px solid rgb(0, 0, 0, 0.2);
  border-radius: 16px;
`

export const HeaderModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 5px;
  margin-bottom: 24px;

  p {
    font-size: 24px;
  }

  span {
    cursor: pointer;
  }
`
export const MainModal = styled.div`
  width: 100%;

  p {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 16px;
  }
`

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 24px 40px 24px 40px;
`

export const RedDeleteIcon = styled(DeleteOutlineIcon)`
  #redDeleteIcon {
    color: red;
  }
`

export const TableWrapper = styled(Box)`
  width: 100%;
  padding: 0 10%;
  padding-bottom: 20px;
`

export const TableCell = styled(MUITableCell)`
  &.MuiTableCell-root {
    padding: 8px 16px;
  }
`

export const IconsWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 0;

  a {
    display: flex;
    align-items: center;
  }
`
