import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ToastNotification from '~/components/ToastNotification'
import ViewLoading from '~/components/ViewLoading'
import { useAuth } from '~/contexts/Auth'
import { deleteSolarInverter, getAllSolarInverter } from '~/services/SolarInverter'
import { deleteSolarModule, getAllSolarModule } from '~/services/SolarModule'
import { deleteSolarTracker, getAllSolarTracker } from '~/services/SolarTracker'
import { AccessLevel } from '~/utils/General'
import AddEditHardwareFormDialog from './AddEditHardwareFormDialog'
import { IHardware } from './types'

interface IAbaProps {
  tabNumber: number
}

const Aba = ({ tabNumber }: IAbaProps) => {
  const { t } = useTranslation()
  const { user } = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [hardwares, setHardwares] = useState<IHardware[]>([])
  const [openAddEditModal, setOpenAddEditModal] = useState(false)
  const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] = useState(false)
  const [selectedHardwareId, setSelectedHardwareId] = useState<number | null>(null)

  const fetchHardwares = async () => {
    setIsLoading(true)
    try {
      let response: any
      if (tabNumber === 0)
        response = await getAllSolarModule({ onlyActive: true, pageNumber: 1, pageSize: 100000 })
      if (tabNumber === 1)
        response = await getAllSolarInverter({ onlyActive: true, pageNumber: 1, pageSize: 100000 })
      if (tabNumber === 2)
        response = await getAllSolarTracker({ onlyActive: true, pageNumber: 1, pageSize: 100000 })
      if (response.success) {
        setHardwares(response.data)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
        })
      }
    } catch (error) {
      console.error(error)
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
      })
    }
    setIsLoading(false)
  }

  const handleDeleteHardware = async (id: number) => {
    try {
      let response: any
      if (tabNumber === 0) response = await deleteSolarModule({ id })
      if (tabNumber === 1) response = await deleteSolarInverter({ id })
      if (tabNumber === 2) response = await deleteSolarTracker({ id })
      if (response.success) {
        setHardwares((prev) => prev.filter((hardware: any) => hardware.id !== id))
        ToastNotification({
          id: 'success',
          type: 'success',
          message: t('Os dados foram excluídos com sucesso!'),
        })
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possível deletar os dados, tente novamente mais tarde.'),
        })
      }
    } catch (error) {
      console.error(error)
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Não foi possível deletar os dados, tente novamente mais tarde.'),
      })
    }
  }

  useEffect(() => {
    if (!openAddEditModal && !openDeleteConfirmationDialog) {
      setHardwares([])
      fetchHardwares()
      setSelectedHardwareId(null)
    }
  }, [tabNumber, openAddEditModal, openDeleteConfirmationDialog])

  return (
    <Paper
      sx={{
        padding: 3,
        margin: '2px calc(10%)',
        backgroundColor: 'white',
        boxShadow: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => {
            setOpenAddEditModal(true)
          }}
          style={{ backgroundColor: '#4CBB27', color: 'white' }}
        >
          {tabNumber === 0 && t('Criar Novo Módulo')}
          {tabNumber === 1 && t('Criar Novo Inversor')}
          {tabNumber === 2 && t('Criar Novo Tracker')}
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead sx={{ bgcolor: 'rgb(21, 95, 153)', height: 80 }}>
            <TableRow>
              <TableCell sx={{ color: 'white', fontWeight: 'bold', fontSize: 16 }}>
                {t('Fabricante')}
              </TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold', fontSize: 16, width: 450 }}>
                {t('Modelo')}
              </TableCell>
              {user?.accessLevel === AccessLevel.Master && (
                <TableCell
                  sx={{ color: 'white', fontWeight: 'bold', fontSize: 16, width: 150 }}
                  align="center"
                >
                  {t('Ações')}
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && hardwares.length === 0 && (
              <TableRow>
                <TableCell
                  colSpan={4}
                  align="center"
                  style={{ height: 180, fontSize: 22, fontWeight: 'bold', paddingTop: 60 }}
                >
                  <ViewLoading />
                </TableCell>
              </TableRow>
            )}
            {!isLoading && hardwares.length === 0 && (
              <TableRow>
                <TableCell
                  colSpan={4}
                  align="center"
                  style={{ height: 180, fontSize: 22, fontWeight: 'bold', paddingTop: 60 }}
                >
                  {t('Nenhum dado encontrado!')}
                </TableCell>
              </TableRow>
            )}
            {!isLoading &&
              hardwares.length > 0 &&
              hardwares.map((hardware) => (
                <TableRow key={hardware.id}>
                  <TableCell>{hardware.manufacturer}</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>{hardware.name}</TableCell>
                  {user?.accessLevel === AccessLevel.Master && (
                    <TableCell
                      align="right"
                      sx={{ display: 'flex', gap: 1, justifyContent: 'center', justifyItems: 'center' }}
                    >
                      <Tooltip title={t('Excluir')}>
                        <IconButton
                          color="error"
                          onClick={() => {
                            setSelectedHardwareId(hardware.id)
                            setOpenDeleteConfirmationDialog(true)
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={t('Editar')}>
                        <IconButton
                          color="primary"
                          onClick={() => {
                            setSelectedHardwareId(hardware.id)
                            setOpenAddEditModal(true)
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openDeleteConfirmationDialog} onClose={() => setOpenDeleteConfirmationDialog(false)}>
        <DialogTitle>{t('Confirmação de Exclusão')}</DialogTitle>
        <DialogContent>
          <p>
            <span>{t('Você tem certeza de que deseja excluir este equipamento?')}</span>
            <br />
            <span style={{ fontWeight: 'bold', color: 'red' }}>
              {t('ATENÇÃO: Essa operação não pode ser desfeita.')}
            </span>
          </p>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              if (selectedHardwareId) handleDeleteHardware(selectedHardwareId)
              setOpenDeleteConfirmationDialog(false)
            }}
            color="error"
            variant="contained"
          >
            {t('Sim')}
          </Button>
          <Button
            onClick={() => {
              setOpenDeleteConfirmationDialog(false)
            }}
            color="primary"
            variant="outlined"
          >
            {t('Não')}
          </Button>
        </DialogActions>
      </Dialog>

      <AddEditHardwareFormDialog
        openModal={openAddEditModal}
        setOpenModal={setOpenAddEditModal}
        selectedHardwareId={selectedHardwareId}
        setSelectedHardwareId={setSelectedHardwareId}
        setSuccess={() => {}}
        tabNumber={tabNumber}
      />
    </Paper>
  )
}

export default Aba
