import { ImageProps } from '~/services/Site/types'

export class Registration {
  id = ''
  name = ''
  local = ''
  descricao = ''
  powerOutput = ''
  moduleLayout = ''
  numberOfModules: any = ''
  combinerCount: any = ''
  inverterCount: any = ''
  transformerCount: any = ''
  electrocenterCount: any = ''
  panelInfo = ''
  latitudeSite = ''
  longitudeSite = ''
  coverImageUrl = ''
  OrthomosaicImage = ''
  electricalDiagramImage = ''
  companyId = ''
  PanelHeight: number | string = 1.5
  CommissioningDate = ''
  parkId: any = ''
  moduleId: any = ''
  inverterId: any = ''
  trackerId: any = ''
}

export class RegistrationError {
  id = ''
  name = ''
  local = ''
  descricao = ''
  powerOutput = ''
  moduleLayout = ''
  numberOfModules: any = ''
  combinerCount: any = ''
  inverterCount: any = ''
  transformerCount: any = ''
  electrocenterCount: any = ''
  panelInfo = ''
  latitudeSite = ''
  longitudeSite = ''
  coverImageUrl = ''
  OrthomosaicImage = ''
  electricalDiagramImage = ''
  companyId = ''
  PanelHeight = ''
  CommissioningDate = ''
  parkId: any = ''
  coordinates = ''
  moduleId: any = ''
  inverterId: any = ''
  trackerId: any = ''
}

export interface FileState {
  name: string
}

export interface ImageValuesProps {
  orthomosaic: ImageProps
  electricalDiagram: ImageProps
  moduleReferenceImage: ImageProps
}
