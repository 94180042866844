import { Stack, Step, StepConnector, StepLabel, Stepper, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import ToastNotification from '~/components/ToastNotification'
import BaseLayout from '~/Layouts/BaseLayout'
import { getCompanySimple } from '~/services/CompaniesAndBranches'
import { getInspectionsAll, patchInspection, postInspections } from '~/services/Inspections'
import { getSiteId } from '~/services/Site'
import ErrorToast from '~/utils/toastErrorCatch'
import { ICompaniesSimple } from '../../CompaniesAndBranches/types'
import EtapaView from './components/etapa'
import Etapa1View from './components/etapa1'
import Etapa2View from './components/etapa2'
import CustomStepIcon from './customStep'
import { ICompanyRemoved } from './types'

const CustomConnector = styled(StepConnector)(({ theme }) => ({
  '& .MuiStepConnector-line': {
    borderWidth: 3,
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
  },
  '&.Mui-active .MuiStepConnector-line, &.Mui-completed .MuiStepConnector-line': {
    borderColor: '#0C4A7D',
  },
}))

const StepperComponent: React.FC = () => {
  const [etapaAtual, setEtapaAtual] = useState(0)
  const etapas = ['Etapa 1', 'Etapa 2', 'Etapa 3', 'Etapa 4', 'Etapa 5', 'Etapa 6']
  const { siteId } = useParams()
  const [companies, setCompanies] = useState<ICompaniesSimple[]>([])
  const [empresaInexistente, setEmpresaInexistente] = useState<ICompanyRemoved>()
  const [status, setStatus] = useState<number>()

  const [selectedCompanyId, setSelectedCompanyId] = useState<string | null>(null)
  const location = useLocation()
  const { edit, key } = location.state || {}
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [inspectionResponse, setInspectionResponse] = useState<string>('')
  const [inspectionSiteName, setInspectionSiteName] = useState<string>('')

  const [etapa1Data, setEtapa1Data] = useState({
    selectedDate: '',
    companyName: '',
    companycontact: '',
    termografista: '',
    auxiliar: '',
    uas: '',
    payload: '',
  })

  const [etapa2Data, setEtapa2Data] = useState({
    humidadeMedia: '',
    temperaturaAmbienteMedia: '',
    temperaturaMediaModulos: '',
    velocidadeMediaVento: '',
    coberturaDasNuvens: '',
    nTotalModulos: '',
    totalMdwcInspecionado: '',
    irradianciaMedia: '',
    temperaturaRefletida: '',
    emissividade: '',
    informacoesAdicionais: '',
    recomendacoes: '',
  })

  useEffect(() => {
    if (edit) {
      loadSite()
      return
    }
    loadDefaultCreateValues()
  }, [edit, key])

  useEffect(() => {
    if (key) {
      setInspectionResponse(siteId ?? '')
    }
  }, [])

  const loadSite = async () => {
    try {
      const response = await getInspectionsAll({
        InspectionId: siteId ?? '',
      })

      if (response.success) {
        const result = response.data[0]
        setStatus(result.status)
        setInspectionSiteName(result.siteName ?? '')

        setEtapa2Data({
          coberturaDasNuvens: result.cloudCoverage ?? '',
          humidadeMedia: result.humidity ?? '',
          temperaturaAmbienteMedia: result.outdoorTemperature ?? '',
          temperaturaMediaModulos: result.moduleTemperature ?? '',
          velocidadeMediaVento: result.windSpeed ?? '',
          nTotalModulos: result.numberOfModules ?? '',
          totalMdwcInspecionado: result.totalPower ?? '',
          irradianciaMedia: result.irradiance ?? '',
          temperaturaRefletida: result.reflectedTemperature ?? '',
          emissividade: result.emissivity ?? '',
          informacoesAdicionais: result.description ?? '',
          recomendacoes: result.recommendations ?? '',
        })
        setEtapa1Data({
          selectedDate: result.inspectionDate ?? '',
          companyName: result.companyName ?? '',
          companycontact: result.companyContactName ?? '',
          termografista: result.thermographer ?? '',
          auxiliar: result.assistants ?? '',
          uas: result.uas ?? '',
          payload: result.payload ?? '',
        })
        setSelectedCompany(result.companyId)
        setEmpresaInexistente({
          companyId: result.companyId ?? '',
          companyName: result.companyName ?? '',
        })
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
          errorMessage: response.errorDetails,
          errors: response.errors,
        })
      }
    } catch (error) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
      })
    }
  }

  const loadDefaultCreateValues = async () => {
    try {
      const response = await getSiteId(siteId ?? '')
      if (response.success) {
        setInspectionSiteName(response.data.name ?? '')
        setEtapa2Data({
          ...etapa2Data,
          nTotalModulos: response.data.numberOfModules ? response.data.numberOfModules.toString() : '',
          totalMdwcInspecionado: response.data.powerOutput
            ? response.data.powerOutput.replace(',', '.')
            : '',
          temperaturaRefletida: '35',
          emissividade: '0,87',
        })
      }
    } catch (error) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
      })
    }
  }

  const novaAnomalia = async (redirectTo?: string) => {
    const inspectionData = {
      siteId: siteId,
      date: etapa1Data.selectedDate,
      companyId: selectedCompanyId,
      companyContactName: etapa1Data.companycontact ?? '',
      thermographer: etapa1Data.termografista ?? '',
      assistants: etapa1Data.auxiliar ?? '',
      uas: etapa1Data.uas ?? '',
      payload: etapa1Data.payload ?? '',
      humidity: etapa2Data.humidadeMedia ?? '',
      windSpeed: etapa2Data.velocidadeMediaVento ?? '',
      outdoorTemperature: etapa2Data.temperaturaAmbienteMedia ?? '',
      moduleTemperature: etapa2Data.temperaturaMediaModulos ?? '',
      cloudCoverage: etapa2Data.coberturaDasNuvens ?? '',
      numberOfModules: etapa2Data.nTotalModulos ?? '',
      emissivity: etapa2Data.emissividade ?? '',
      irradiance: etapa2Data.irradianciaMedia ?? '',
      reflectedTemperature: etapa2Data.temperaturaRefletida ?? '',
      description: etapa2Data.informacoesAdicionais ?? '',
      totalPower: etapa2Data.totalMdwcInspecionado ?? '',
      recommendations: etapa2Data.recomendacoes ?? '',
      id: inspectionResponse ?? '',
    }

    try {
      if (edit) {
        inspectionData.recommendations = etapa2Data.recomendacoes ?? ''
        inspectionData.id = siteId ?? ''
      }

      if (!edit && (!inspectionResponse || inspectionResponse.length === 0)) {
        let response = await postInspections(inspectionData)
        setInspectionResponse(response.data)
        if (response.success) {
          ToastNotification({
            id: 'sucess',
            type: 'success',
            message: t('Inspeção criado com sucesso!'),
          })
          if (redirectTo) {
            navigate(redirectTo)
          } else {
            handleNext()
          }
        }
      } else {
        let response = await patchInspection(inspectionData)
        if (response.success) {
          ToastNotification({
            id: 'sucess',
            type: 'success',
            message: t('Inspeção atualizado com sucesso!'),
          })
        }
        if (redirectTo) {
          navigate(redirectTo)
        } else {
          handleNext()
        }
      }
    } catch (error) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Não foi possível realizar o envio das inspeção, tente novamente mais tarde.'),
      })
    }
  }

  const handleFieldChange = (fieldName: string, value: string) => {
    setEtapa1Data((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }))
    setEtapa2Data((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }))
  }

  const setSelectedCompany = (id: string | null) => {
    setSelectedCompanyId(id)
  }

  const saveInspection = (redirectTo?: string) => {
    novaAnomalia(redirectTo)
  }

  const handleNext = () => {
    setEtapaAtual((prevEtapa) => prevEtapa + 1)
  }

  const handleBack = () => {
    setEtapaAtual((prevEtapa) => prevEtapa - 1)
  }

  const renderEtapa = () => {
    switch (etapaAtual) {
      case 0:
        return (
          <Etapa1View
            handleBack={handleBack}
            handleNext={handleNext}
            etapa1Data={etapa1Data}
            onFieldChange={handleFieldChange}
            companies={companies}
            selectCompany={selectedCompanyId}
            setSelectedCompanyId={setSelectedCompany}
            empresaInexistente={empresaInexistente}
          />
        )
      case 1:
        return (
          <Etapa2View
            etapa2Data={etapa2Data}
            handleBack={handleBack}
            handleNext={handleNext}
            onFieldChange={handleFieldChange}
            edit={edit}
            saveInspection={saveInspection}
            status={status}
          />
        )
      default:
        return (
          <EtapaView
            stepNumber={etapaAtual}
            handleBack={handleBack}
            handleNext={handleNext}
            inspecId={inspectionResponse}
          />
        )
    }
  }

  useEffect(() => {
    const handleOpen = async () => {
      try {
        const response = await getCompanySimple({
          OnlyHeadOffices: false,
          ConsiderUserCompany: false,
          ReturnOnlyInspectorCompanies: true,
        })
        setCompanies(response.data)
      } catch (error) {
        return <ErrorToast message={t('Não foi possível buscar as empresas.')} />
      }
    }

    handleOpen()
  }, [])

  return (
    <div>
      <BaseLayout
        title={
          inspectionSiteName ? t('Enviar Imagens') + ': ' + inspectionSiteName : t('Enviar Imagens')
        }
      >
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ width: '100%', marginTop: '2rem' }}
        >
          <Stepper
            alternativeLabel
            activeStep={etapaAtual}
            connector={<CustomConnector />}
            sx={{ width: '50%' }}
          >
            {etapas.map((label, index) => (
              <Step key={label}>
                <StepLabel
                  StepIconComponent={(props) => (
                    <CustomStepIcon
                      active={etapaAtual === index}
                      completed={etapaAtual > index}
                      icon={index + 1}
                      {...props}
                    />
                  )}
                />
              </Step>
            ))}
          </Stepper>
        </Stack>
      </BaseLayout>
      {renderEtapa()}
    </div>
  )
}

export default StepperComponent
