import FilterAltIcon from '@mui/icons-material/FilterAlt'
import { Button } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Map, { Layer, Marker, NavigationControl, Popup, Source } from 'react-map-gl'
import { useLocation } from 'react-router-dom'
import Select from 'react-select'
import uuid from 'react-uuid'
import ToastNotification from '~/components/ToastNotification'
import BaseLayout from '~/Layouts/BaseLayout'
import { SelectOption } from '~/pages/Dashboard/Reports/types'
import {
  deleteAnomalyByTypeService,
  deleteAnomalyService,
  getAnomalyDetailsService,
  getAnomalySimple,
  getAnomalyStatus,
  postManualLocal,
} from '~/services/Anomaly'
import {
  AnomalySimpleDTO,
  IAnomalyLIstProps,
  IRequestAnomaly,
  IRequestManualLocal,
  IRequestSubpark,
  IResponseAnomalyStatus,
} from '~/services/Anomaly/types'
import { getInspectionsSimple, getSubparkAreas } from '~/services/Inspections'
import { getSimpleSite } from '~/services/Site'
import { getAnomalyColor } from '~/utils/Anomalies'
import ErrorToast from '~/utils/toastErrorCatch'
import Modal from '../Modal'
import FiltersModal from './components/filters'
import ListaToggle from './components/ListItens'
import { NewSidebarOneAnomaly } from './components/NewSideBarOneAnomaly'
import AnomaliesDetails from './Detalhes'
import * as S from './styles'
import { defaultSelectProps } from './utils'

interface IMapViewState {
  longitude: number
  latitude: number
  zoom: number
}

function Mapbox() {
  const { t } = useTranslation()
  const { state } = useLocation()

  const [mapViewState, setMapViewState] = useState<IMapViewState>({
    longitude: -0,
    latitude: 0,
    zoom: 3,
  })
  const [cursor, setCursor] = useState<string>('grab')
  const [showDetails, setShowDetails] = useState<boolean>(false)
  const [showFilterModal, setShowFilterModal] = useState<boolean>(false)
  const [showSidebar, setShowSidebar] = useState<boolean>(false)
  const [isEditingCoordinates, setIsEditingCoordinates] = useState<boolean>(false)
  const [fetchDetailsData, setFetchDetailsData] = useState<boolean>(false)
  const [hoveredAnomaly, setHoveredAnomaly] = useState<any>(null)
  const [editingAnomaly, setEditingAnomaly] = useState<AnomalySimpleDTO | null>(null)
  const [anomalyToDelete, setAnomalyToDelete] = useState<AnomalySimpleDTO | IAnomalyLIstProps | null>(
    null,
  )
  const [siteOptions, setSiteOptions] = useState<SelectOption[]>([])
  const [inspectionOptions, setInspectionOptions] = useState<SelectOption[]>([])
  const [selectedSiteId, setSelectedSiteId] = useState<string>('')
  const [selectedInspectionId, setSelectedInspectionId] = useState<string>('')
  const [typesFilters, setTypesFilters] = useState<number[]>([])
  const [statusesFilters, setStatusesFilters] = useState<number[]>([])
  const [severitiesFilters, setSeveritiesFilters] = useState<number[]>([])
  const [anomalyStatuses, setAnomalyStatuses] = useState<IResponseAnomalyStatus[]>([])
  const [parkData, setParkData] = useState<any>({})
  const [subparksData, setSubparksData] = useState<any>({})
  const [stringsData, setStringsData] = useState<any>({})
  const [modulesData, setModulesData] = useState<any>({})
  const [anomaliesStringsData, setAnomaliesStringsData] = useState<any>({})
  const [anomaliesModulesData, setAnomaliesModulesData] = useState<any>({})
  const [anomaliesOrphansData, setAnomaliesOrphansData] = useState<any>({})
  const [allAnomaliesData, setAllAnomaliesData] = useState<any[]>([])
  const [currentAnomalyIndex, setCurrentAnomalyIndex] = useState<number>(0)
  const [anomaliesCountByType, setAnomaliesCountByType] = useState<any>([])
  const [anomaliesListInfo, setAnomaliesListInfo] = useState<boolean>(false)

  const loadSites = async () => {
    try {
      const site = await getSimpleSite()
      if (site.success) {
        const options = site.data.map((site) => ({
          label: site.nameAndLocal,
          value: site.id,
        }))
        setSiteOptions(options)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possível carregar os Sites, tente novamente mais tarde.'),
          errorMessage: site.errorDetails,
          errors: site.errors,
        })
      }
    } catch (error) {
      return (
        <ErrorToast message={t('Não foi possível carregar as empresa, tente novamente mais tarde.')} />
      )
    }
  }

  const loadInspection = async () => {
    try {
      const inspection = await getInspectionsSimple({
        SiteId: selectedSiteId,
        ShowOnlyCompleted: false,
      })
      if (inspection.success) {
        const options = inspection.data.map((inspection) => ({
          label: inspection.dateAndDescription,
          value: inspection.id,
        }))
        setInspectionOptions(options)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possível carregar as Inspeções, tente novamente mais tarde.'),
          errorMessage: inspection.errorDetails,
          errors: inspection.errors,
        })
      }
    } catch (error) {
      return (
        <ErrorToast message={t('Não foi possível carregar as empresa, tente novamente mais tarde.')} />
      )
    }
  }

  const handleFilterAnomalies = (values: {
    types: number[]
    statuses: number[]
    severities: number[]
  }) => {
    setTypesFilters(values.types)
    setStatusesFilters(values.statuses)
    setSeveritiesFilters(values.severities)
    callAllData(true, values.types, values.statuses, values.severities)
    ToastNotification({
      id: 'success',
      type: 'success',
      message: t('Filtros Aplicados'),
      errorMessage: undefined,
      errors: undefined,
    })
    setShowFilterModal(false)
    setShowSidebar(false)
  }

  const loadAnomalyStatuses = async () => {
    try {
      const response = await getAnomalyStatus()
      if (response.success) {
        setAnomalyStatuses(response.data)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
          errorMessage: response.errorDetails,
          errors: response.errors,
        })
      }
    } catch (error) {
      return (
        <ErrorToast message={t('Não foi possível carregar os status, tente novamente mais tarde.')} />
      )
    }
  }

  const callAllData: any = async (
    onlyAnomalies: boolean = false,
    anomalyTypes: number[] = [],
    anomalyStatuses: number[] = [],
    anomalySeverities: number[] = [],
  ) => {
    const requestParams: IRequestAnomaly = {
      InspectionId: selectedInspectionId,
      V2: true,
      ...(anomalyTypes.length > 0 && { AnomalyTypes: anomalyTypes }),
      ...(anomalyStatuses.length > 0 && { Statuses: anomalyStatuses }),
      ...(anomalySeverities.length > 0 && { Severities: anomalySeverities }),
    }

    try {
      const response = await getAnomalySimple(requestParams)

      if (response.success) {
        if (onlyAnomalies || response.data.park == null) {
          loadAnomalies(response.data)
          return
        }

        loadPark(response.data)
        loadSubparks(response.data)
        loadStrings(response.data)
        loadModules(response.data)
        loadAnomalies(response.data)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
          errorMessage: response.errorDetails,
          errors: response.errors,
        })
      }
    } catch (error) {
      console.error(error)
      return (
        <ErrorToast message={t('Não foi possível carregar os dados, tente novamente mais tarde.')} />
      )
    }
  }

  const loadPark = async (response: any) => {
    const tempData = {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [response.park.center[1], response.park.center[0]],
          },
          properties: {
            parkName: response.park.name,
          },
        },
      ],
    }

    setAnomaliesCountByType(response.anomaliesCount)
    setParkData(tempData)

    setMapViewState({
      longitude: tempData.features[0].geometry.coordinates[0],
      latitude: tempData.features[0].geometry.coordinates[1],
      zoom: 14.5,
    })
  }

  const loadSubparks = async (response: any) => {
    const tempData = {
      type: 'FeatureCollection',
      features: response.park.subparks.map((subpark: any) => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [subpark.center[1], subpark.center[0]],
        },
        properties: {
          subparkName: subpark.name,
        },
      })),
    }

    setSubparksData(tempData)
  }

  const loadStrings = async (response: any) => {
    try {
      const fetchPromises = response.park.subparks.map((subpark: any) =>
        getSubparkAreas({
          SubparkId: subpark.id,
          GetModules: false,
        } as IRequestSubpark),
      )

      const promisesResponses = await Promise.all(fetchPromises)

      const tempData = {
        type: 'FeatureCollection',
        features: promisesResponses.flatMap((response: any) => {
          return response.data.strings.map((string: any) => ({
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: [string.coordinates.map((coord: any) => [coord[1], coord[0]])],
            },
            properties: {
              stringId: string.id,
              stringName: string.name,
            },
          }))
        }),
      }

      setStringsData(tempData)
    } catch (error) {
      console.error(error)
      return (
        <ErrorToast message={t('Não foi possível carregar os dados, tente novamente mais tarde.')} />
      )
    }
  }

  const loadModules = async (response: any) => {
    try {
      const fetchPromises = response.park.subparks.map((subpark: any) =>
        getSubparkAreas({
          SubparkId: subpark.id,
          GetModules: true,
        } as IRequestSubpark),
      )

      const promisesResponses = await Promise.all(fetchPromises)

      const tempData = {
        type: 'FeatureCollection',
        features: promisesResponses.flatMap((response: any) => {
          return response.data.modules.map((module: any) => ({
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [module.center[1], module.center[0]],
            },
            properties: {
              moduleNumber: module.number,
            },
          }))
        }),
      }

      setModulesData(tempData)
    } catch (error) {
      console.error(error)
      return (
        <ErrorToast message={t('Não foi possível carregar os dados, tente novamente mais tarde.')} />
      )
    }
  }

  const loadAnomalies = async (response: any) => {
    let tempStrings: any = {
      type: 'FeatureCollection',
      features: [],
    }
    let tempModules: any = {
      type: 'FeatureCollection',
      features: [],
    }
    let tempOrphans: any = {
      type: 'FeatureCollection',
      features: [],
    }

    response.anomalies.forEach((anomaly: any) => {
      const properties = {
        anomalyId: anomaly.id,
        anomalyCode: anomaly.code,
        anomalyType: anomaly.originalTypeName,
        anomalyColor: getAnomalyColor(anomaly.originalTypeName),
        anomalyStatus: anomaly.status,
        anomalyName: anomaly.name,
        anomalyImage: anomaly.labelledImageUrl,
        anomalyLatitude: anomaly.latitude,
        anomalyLongitude: anomaly.longitude,
        anomalyManualLatitude: anomaly.manualLatitude,
        anomalyManualLongitude: anomaly.manualLongitude,
      }

      if (anomaly.moduleCoordinates.length > 0) {
        tempModules.features.push({
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [anomaly.moduleCoordinates.map((coord: any) => [coord[1], coord[0]])],
          },
          properties: properties,
        })

        if (anomaly.typeId === 1 || anomaly.typeId === 2) {
          tempStrings.features.push({
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: [anomaly.stringModuleCoordinates.map((coord: any) => [coord[1], coord[0]])],
            },
            properties: {
              stringId: anomaly.stringModuleId,
            },
          })
        }
      } else {
        tempOrphans.features.push({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [anomaly.longitude, anomaly.latitude],
          },
          properties: properties,
        })
      }
    })

    setAnomaliesStringsData(tempStrings)
    setAnomaliesModulesData(tempModules)
    setAnomaliesOrphansData(tempOrphans)
  }

  const showAnomalyDetails = async (id: string) => {
    try {
      const response = await getAnomalyDetailsService(id)
      if (response.success) {
        setShowSidebar(true)
        setEditingAnomaly(response.data)
        setMapViewState({
          longitude: response.data.manualLongitude ?? response.data.longitude,
          latitude: response.data.manualLatitude ?? response.data.latitude,
          zoom: mapViewState.zoom >= 18 ? mapViewState.zoom : 18,
        })
      }
    } catch (error) {
      console.error('Error Updating Anomaly Details: ' + error)
    }
  }

  const handlePreviousAnomaly = () => {
    const newIndex = currentAnomalyIndex === 0 ? allAnomaliesData.length - 1 : currentAnomalyIndex - 1
    showAnomalyDetails(allAnomaliesData[newIndex].properties.anomalyId)
    setCurrentAnomalyIndex(newIndex)
  }

  const handleNextAnomaly = () => {
    const newIndex = currentAnomalyIndex === allAnomaliesData.length - 1 ? 0 : currentAnomalyIndex + 1
    showAnomalyDetails(allAnomaliesData[newIndex].properties.anomalyId)
    setCurrentAnomalyIndex(newIndex)
  }

  const handleChangeAnomalyLocation = async (
    selectedAnomaly: any,
    resetAnomalyLocation: boolean,
    clickedStringId?: number,
  ) => {
    const params: IRequestManualLocal = {
      anomalyId: selectedAnomaly.id,
      latitude: selectedAnomaly.latitude,
      longitude: selectedAnomaly.longitude,
      reset: resetAnomalyLocation,
      stringId: clickedStringId,
    }

    try {
      const response = await postManualLocal(params)
      if (response.success) {
        callAllData(true, typesFilters, statusesFilters, severitiesFilters)
        ToastNotification({
          id: 'success',
          type: 'success',
          message: t('Anomalia alterada com sucesso.'),
          errorMessage: response.errorDetails,
          errors: response.errors,
        })
        showAnomalyDetails(selectedAnomaly.id)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possível alterar a localização, tente novamente mais tarde.'),
          errorMessage: response.errorDetails,
          errors: response.errors,
        })
      }
    } catch (error) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
      })
    }
  }

  const handleMapClick = (event: any) => {
    const { lng, lat } = event.lngLat
    const stringFeature = event.features.find((feature: any) => feature.source == 'strings')
    const anomalyFeature = event.features.find((feature: any) => feature.source == 'anomalies')
    const orphanFeature = event.features.find((feature: any) => feature.source == 'orphan-anomalies')

    if (isEditingCoordinates) {
      if (event.features.length >= 2 && !anomalyFeature) {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t(
            'É necessário aumentar mais o zoom para selecionar a localização da anomalia, tente novamente.',
          ),
        })
        setIsEditingCoordinates(false)
        return
      }
      if (event.features.length >= 2 && anomalyFeature) {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t(
            'Não é possível mudar a localização de uma anomalia para um módulo com anomalia, tente novamente.',
          ),
        })
        setIsEditingCoordinates(false)
        return
      }
      if (!stringFeature) {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t(
            'Não é possível mudar a localização de uma anomalia para fora de um módulo, tente novamente.',
          ),
        })
        setIsEditingCoordinates(false)
        return
      }

      handleChangeAnomalyLocation(
        {
          ...editingAnomaly,
          latitude: lat,
          longitude: lng,
        },
        false,
        stringFeature.properties.stringId,
      )
      setIsEditingCoordinates(false)
      return
    }

    if (orphanFeature) {
      showAnomalyDetails(orphanFeature.properties.anomalyId)
      return
    }
    if (anomalyFeature) {
      showAnomalyDetails(anomalyFeature.properties.anomalyId)
    }
  }

  const handleMapMouseEnter = (event: any) => {
    setCursor('pointer')

    const orphanAnomaly = event.features.find((feature: any) => feature.source === 'orphan-anomalies')
    const moduleAnomaly = event.features.find((feature: any) => feature.source === 'anomalies')

    if (orphanAnomaly) {
      setHoveredAnomaly(orphanAnomaly)
    }
    if (moduleAnomaly) {
      setHoveredAnomaly(moduleAnomaly)
    }
  }

  const handleDeleteAnomaly = async (anomalyId: string) => {
    try {
      const response = await deleteAnomalyService(anomalyId)
      if (response.success) {
        setAnomalyToDelete(null)
        callAllData(true, typesFilters, statusesFilters, severitiesFilters)
        setFetchDetailsData(true)
        ToastNotification({
          id: 'success',
          type: 'success',
          message: 'Anomalia excluída com sucesso!',
          errorMessage: response.errorDetails,
          errors: response.errors,
        })
        setShowSidebar(false)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: response.message,
          errorMessage: response.errorDetails,
          errors: response.errors,
        })
      }
    } catch (error) {
      return (
        <ErrorToast message={t('Não foi possível carregar as empresa, tente novamente mais tarde.')} />
      )
    }
  }

  const deleteAnomalyByType = async (anomalies: number[]) => {
    try {
      const resp = await deleteAnomalyByTypeService(anomalies, selectedInspectionId)
      if (resp.success) {
        callAllData(true, typesFilters, statusesFilters, severitiesFilters)
        setFetchDetailsData(true)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: resp.message,
          errorMessage: resp.errorDetails,
          errors: resp.errors,
        })
      }
    } catch (error) {
      return (
        <ErrorToast message={t('Não foi possível carregar as empresa, tente novamente mais tarde.')} />
      )
    }
  }

  const selectProps = {
    ...defaultSelectProps,
    name: t('Selecione') ?? 'Selecione*',
    placeholder: t('Selecione a Inspeção*') ?? 'Selecione a Inspeção*',
    noOptionsMessage: () => t('Nenhuma opção disponível'),
  }

  useEffect(() => {
    loadAnomalyStatuses()
    loadSites()
  }, [])

  useEffect(() => {
    if (selectedSiteId) {
      loadInspection()
    }
  }, [selectedSiteId])

  useEffect(() => {
    if (selectedInspectionId) {
      callAllData()
      setShowSidebar(false)
    }
  }, [selectedInspectionId])

  useEffect(() => {
    if (state) {
      setSelectedSiteId(state.siteId)
      setSelectedInspectionId(state.inspectionId)
    }
  }, [state])

  useEffect(() => {
    let tempAllAnomalies: any = []

    if (Object.keys(anomaliesModulesData).length > 0 && anomaliesModulesData.features.length > 0) {
      tempAllAnomalies = anomaliesModulesData.features
    }
    if (Object.keys(anomaliesOrphansData).length > 0 && anomaliesOrphansData.features.length > 0) {
      tempAllAnomalies = tempAllAnomalies.concat(anomaliesOrphansData.features)
    }

    setAllAnomaliesData(
      tempAllAnomalies.sort((a: any, b: any) => a.properties.anomalyId - b.properties.anomalyId),
    )
  }, [anomaliesModulesData, anomaliesOrphansData])

  useEffect(() => {
    setCurrentAnomalyIndex(0)
  }, [showSidebar])

  return (
    <BaseLayout
      title={showDetails ? t('Detalhes') : t('Mapa')}
      extraContent={
        <S.FormWrapper>
          <S.FormControl>
            <Select
              {...{ selectProps }}
              value={siteOptions.find((option) => option.value === selectedSiteId)}
              onChange={(selectedOption) => {
                if (selectedOption) {
                  setSelectedSiteId(selectedOption.value)
                } else {
                  setSelectedSiteId('')
                  setSelectedInspectionId('')
                }
              }}
              options={siteOptions}
              placeholder={t('Selecione o Sítio')}
              styles={{ menu: (provided) => ({ ...provided, zIndex: 1000 }) }}
            />
          </S.FormControl>

          <S.FormControl>
            <Select
              {...{ selectProps }}
              value={
                selectedSiteId
                  ? inspectionOptions.find((inspection) => inspection.value === selectedInspectionId)
                  : ''
              }
              onChange={(selectedOption) => {
                if (selectedOption && typeof selectedOption === 'object' && 'value' in selectedOption) {
                  setSelectedInspectionId(selectedOption.value)
                } else {
                  setSelectedInspectionId('')
                }
              }}
              isDisabled={selectedSiteId === ''}
              options={inspectionOptions}
              placeholder={t('Selecione a Inspeção')}
              styles={{ menu: (provided) => ({ ...provided, zIndex: 1000 }) }}
            />
          </S.FormControl>

          {!showDetails && (
            <Button
              variant="outlined"
              onClick={() => setShowFilterModal(!showFilterModal)}
              disabled={selectedInspectionId === ''}
            >
              <FilterAltIcon />
            </Button>
          )}

          <Button
            variant="contained"
            onClick={() => {
              setShowDetails(!showDetails)
              setShowSidebar(false)
            }}
            sx={{ minWidth: '100px' }}
            disabled={selectedInspectionId === ''}
          >
            {!showDetails ? t('Detalhes') : t('Mapa')}
          </Button>
        </S.FormWrapper>
      }
    >
      <S.FlexWrapper>
        {showDetails ? (
          <AnomaliesDetails
            stringsData={stringsData}
            handleFetchData={(value: boolean) => setFetchDetailsData(value)}
            fetchDetailsData={fetchDetailsData}
            selectedInspection={
              inspectionOptions.find(
                (inspection) => inspection.value === selectedInspectionId,
              ) as SelectOption
            }
            removeAnomalies={deleteAnomalyByType}
            handleShowDetails={(id: string) => showAnomalyDetails(id)}
            deleteAnomaly={(anomaly) => setAnomalyToDelete(anomaly)}
            inspectionId={selectedInspectionId}
          />
        ) : (
          <Map
            id={uuid()}
            {...mapViewState}
            mapboxAccessToken="pk.eyJ1IjoidGh5ZXJyeSIsImEiOiJjbGU3aWUxeW4wNW93M3FxZ250Yjg3ODg1In0.C1hUGICSXdbIAYWI47L4Bg"
            mapStyle="mapbox://styles/mapbox/satellite-v9?optimize=true"
            style={{ width: '100%', height: '100%' }}
            pitch={0}
            cursor={cursor}
            renderWorldCopies={false}
            doubleClickZoom={false}
            interactiveLayerIds={['strings-polygons', 'anomalies-polygons', 'orphan-anomalies-circles']}
            onMove={({ viewState }) => setMapViewState(viewState)}
            onClick={(event: any) => handleMapClick(event)}
            onMouseEnter={(event: any) => handleMapMouseEnter(event)}
            onMouseLeave={() => {
              setCursor('grab')
              setHoveredAnomaly(null)
            }}
            onDragStart={() => setCursor('grabbing')}
            onDragEnd={() => setCursor('grab')}
          >
            {anomaliesCountByType.length && (
              <ListaToggle
                isListVisible={anomaliesListInfo}
                toggleList={() => setAnomaliesListInfo(!anomaliesListInfo)}
                listItems={anomaliesCountByType}
              />
            )}
            <NavigationControl style={{ right: 10, top: 10 }} />

            {!!Object.keys(parkData).length && (
              <Source id="park" type="geojson" data={parkData}>
                <Layer
                  id="park-label"
                  type="symbol"
                  source="park"
                  maxzoom={13}
                  layout={{
                    'text-anchor': 'center',
                    'text-justify': 'center',
                    // 'text-allow-overlap': true,
                    'text-font': ['Open Sans SemiBold'],
                    'text-size': 13,
                    'text-field': ['get', 'parkName'],
                  }}
                />
              </Source>
            )}

            {!!Object.keys(subparksData).length && (
              <Source id="subparks" type="geojson" data={subparksData}>
                <Layer
                  id="subparks-label"
                  type="symbol"
                  source="subparks"
                  minzoom={13}
                  maxzoom={15}
                  layout={{
                    'text-anchor': 'center',
                    'text-justify': 'center',
                    'text-allow-overlap': true,
                    'text-font': ['Open Sans SemiBold'],
                    'text-size': 13,
                    'text-field': ['get', 'subparkName'],
                  }}
                />
              </Source>
            )}

            {!!Object.keys(stringsData).length && (
              <Source id="strings" type="geojson" data={stringsData}>
                {/* <Source id="strings" type="vector" url="mapbox://lucascastro-marttech.br8nwwlt"> */}
                <Layer
                  id="strings-polygons"
                  type="fill"
                  source="strings"
                  // source-layer="strings-0l0ojj"
                  minzoom={15}
                  paint={{
                    'fill-color': 'transparent',
                    'fill-outline-color': '#0078D7',
                  }}
                />
                <Layer
                  id="strings-labels"
                  type="symbol"
                  source="strings"
                  // source-layer="strings-0l0ojj"
                  minzoom={15}
                  maxzoom={20.5}
                  layout={{
                    'text-anchor': 'center',
                    'text-justify': 'center',
                    // 'text-allow-overlap': true,
                    'text-font': ['Open Sans SemiBold'],
                    'text-size': ['interpolate', ['exponential', 2], ['zoom'], 15, 7, 20.5, 11],
                    'text-field': ['get', 'stringName'],
                  }}
                  paint={{
                    'text-color': '#FFFFFF',
                  }}
                />
              </Source>
            )}

            {!!Object.keys(anomaliesStringsData).length && (
              <Source id="string-anomalies" type="geojson" data={anomaliesStringsData}>
                <Layer
                  id="string-anomalies-polygons"
                  type="fill"
                  source="anomalies"
                  minzoom={15}
                  paint={{
                    'fill-color': '#FF0000',
                    'fill-opacity': 0.3,
                  }}
                />
              </Source>
            )}

            {!!Object.keys(anomaliesModulesData).length && (
              <Source id="anomalies" type="geojson" data={anomaliesModulesData}>
                <Layer
                  id="anomalies-polygons"
                  type="fill"
                  source="anomalies"
                  minzoom={15}
                  paint={{
                    'fill-color': ['get', 'anomalyColor'],
                  }}
                />
              </Source>
            )}

            {!!Object.keys(anomaliesOrphansData).length && (
              <Source id="orphan-anomalies" type="geojson" data={anomaliesOrphansData}>
                <Layer
                  id="orphan-anomalies-circles"
                  type="circle"
                  source="orphan-anomalies"
                  minzoom={0}
                  paint={{
                    'circle-color': '#FFFFFF',
                    'circle-radius': 5,
                    'circle-stroke-color': ['get', 'anomalyColor'],
                    'circle-stroke-width': 5,
                  }}
                />
              </Source>
            )}

            {!!Object.keys(modulesData).length && (
              <Source id="modules" type="geojson" data={modulesData}>
                {/* <Source id="modules" type="vector" url="mapbox://lucascastro-marttech.0a4szsqf"> */}
                <Layer
                  id="modules-labels"
                  type="symbol"
                  source="modules"
                  // source-layer="modules-2gdpjw"
                  minzoom={20.5}
                  layout={{
                    'text-anchor': 'center',
                    'text-justify': 'center',
                    // 'text-allow-overlap': true,
                    'text-font': ['Open Sans SemiBold'],
                    'text-size': ['interpolate', ['exponential', 2], ['zoom'], 20.5, 5, 22, 15],
                    'text-field': ['get', 'moduleNumber'],
                  }}
                  paint={{
                    'text-color': '#FFFFFF',
                  }}
                />
              </Source>
            )}

            {hoveredAnomaly && (
              <Popup
                closeButton={false}
                closeOnClick={false}
                latitude={
                  hoveredAnomaly.properties.anomalyManualLatitude ??
                  hoveredAnomaly.properties.anomalyLatitude
                }
                longitude={
                  hoveredAnomaly.properties.anomalyManualLongitude ??
                  hoveredAnomaly.properties.anomalyLongitude
                }
                style={{ textAlign: 'center' }}
              >
                <img
                  src={hoveredAnomaly.properties.anomalyImage}
                  alt={hoveredAnomaly.properties.anomalyName}
                  style={{ width: '150px', height: '100px' }}
                />
                <p style={{ fontWeight: 'bold' }}>
                  #{hoveredAnomaly.properties.anomalyCode} - {hoveredAnomaly.properties.anomalyName}
                </p>
                <p>
                  Status:{' '}
                  {anomalyStatuses.find(
                    (status: any) => status.id === hoveredAnomaly.properties.anomalyStatus,
                  )?.status ?? 'Status Desconhecido'}
                </p>
              </Popup>
            )}

            {editingAnomaly && showSidebar && (
              <Marker
                latitude={editingAnomaly.manualLatitude ?? editingAnomaly.latitude}
                longitude={editingAnomaly.manualLongitude ?? editingAnomaly.longitude}
              />
            )}
          </Map>
        )}
        {showSidebar && (
          <NewSidebarOneAnomaly
            anomaly={editingAnomaly}
            handleClose={() => setShowSidebar(false)}
            onAnomalySaved={() => {
              callAllData(true, typesFilters, statusesFilters, severitiesFilters)
              editingAnomaly && showAnomalyDetails(editingAnomaly.id)
            }}
            deleteAnomaly={(anomaly: any) => setAnomalyToDelete(anomaly)}
            isEditingCoordinates={isEditingCoordinates}
            setIsEditingCoordinates={setIsEditingCoordinates}
            setReset={() => handleChangeAnomalyLocation(editingAnomaly, true)}
            handlePrevious={handlePreviousAnomaly}
            handleNext={handleNextAnomaly}
          />
        )}

        <FiltersModal
          inspectionId={selectedInspectionId}
          open={showFilterModal}
          onClose={() => setShowFilterModal(false)}
          onSave={handleFilterAnomalies}
        />

        <Modal
          onClose={() => setAnomalyToDelete(null)}
          open={!!anomalyToDelete}
          title={t('Remover Anomalia')}
          confirmButtonText={t('Remover') ?? ''}
          cancelButtonText={t('Cancelar') ?? ''}
          cancelButtonAction={() => setAnomalyToDelete(null)}
          confirmButtonAction={() => anomalyToDelete && handleDeleteAnomaly(anomalyToDelete.id)}
        >
          {t('Você tem certeza que deseja excluir esta anomalia?')}
          <strong>{` #${anomalyToDelete?.code} ${anomalyToDelete?.name}`}</strong>
        </Modal>
      </S.FlexWrapper>
    </BaseLayout>
  )
}

export default Mapbox
