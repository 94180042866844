import { deleteJson, getJson, postJson, putJson } from '../RequestService'
import { IRequestSolarInverter, IResponseSolarInverter } from './types'

const defaultRoot = 'solarinverter'

export const getAllSolarInverter = async (data: IRequestSolarInverter) => {
  let url = `${defaultRoot}?`
  if (data.filter) url += `Filter=${data.filter}`
  if (data.onlyActive) url += `&OnlyActive=${data.onlyActive}`
  if (data.pageNumber) url += `&PageNumber=${data.pageNumber}`
  if (data.pageSize) url += `&PageSize=${data.pageSize}`
  return await getJson<IResponseSolarInverter[]>(url)
}

export const getSolarInverter = async (data: IRequestSolarInverter) => {
  let url = `${defaultRoot}`
  if (data.id) url += `/${data.id}`
  return await getJson<IResponseSolarInverter[]>(url)
}

export const postSolarInverter = async (data: IResponseSolarInverter) => {
  let url = `${defaultRoot}?`
  return await postJson<IResponseSolarInverter, IResponseSolarInverter>(url, data)
}

export const patchSolarInverter = async (data: IResponseSolarInverter) => {
  let url = `${defaultRoot}?`
  return await putJson<IResponseSolarInverter, IResponseSolarInverter>(url, data)
}

export const deleteSolarInverter = async (data: IRequestSolarInverter) => {
  let url = `${defaultRoot}?`
  url += `id=${data.id}`
  return await deleteJson<IRequestSolarInverter, IResponseSolarInverter>(url, data)
}
