import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
} from '@mui/material'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import ToastNotification from '~/components/ToastNotification'
import { getSolarInverter, patchSolarInverter, postSolarInverter } from '~/services/SolarInverter'
import { getSolarModule, patchSolarModule, postSolarModule } from '~/services/SolarModule'
import { getSolarTracker, patchSolarTracker, postSolarTracker } from '~/services/SolarTracker'
import { IHardwareForm } from './types'

interface IAddEditHardwareFormDialogProps {
  openModal: boolean
  setOpenModal: (value: boolean) => void
  selectedHardwareId: number | null
  setSelectedHardwareId: (value: number | null) => void
  tabNumber: number
  setSuccess: (value: boolean) => void
}

const AddEditHardwareFormDialog = ({
  openModal,
  setOpenModal,
  selectedHardwareId,
  setSelectedHardwareId,
  tabNumber,
  setSuccess,
}: IAddEditHardwareFormDialogProps) => {
  const { t } = useTranslation()
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<IHardwareForm>({
    defaultValues: { id: null, name: null, manufacturer: null, power: null, acOutput: null },
  })

  const loadHardware = async () => {
    if (!selectedHardwareId) return
    try {
      let response: any
      if (tabNumber === 0) response = await getSolarModule({ id: selectedHardwareId })
      if (tabNumber === 1) response = await getSolarInverter({ id: selectedHardwareId })
      if (tabNumber === 2) response = await getSolarTracker({ id: selectedHardwareId })
      if (response.data) {
        setValue('id', response.data.id)
        setValue('name', response.data.name)
        setValue('manufacturer', response.data.manufacturer)
        if (response.data.power) setValue('power', response.data.power)
        if (response.data.acOutput) setValue('acOutput', response.data.acOutput)
      }
    } catch (error) {
      console.error(error)
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
      })
    }
  }

  const handleAddHardware = async (data: IHardwareForm) => {
    try {
      let response: any
      if (tabNumber === 0) response = await postSolarModule(data)
      if (tabNumber === 1) response = await postSolarInverter(data)
      if (tabNumber === 2) response = await postSolarTracker(data)
      if (response.success) {
        reset()
        setOpenModal(false)
        ToastNotification({
          id: 'success',
          type: 'success',
          message: t('Os dados foram adicionados com sucesso!'),
        })
        setSuccess(true)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possível adicionar os dados, tente novamente mais tarde.'),
        })
      }
    } catch (error) {
      console.error(error)
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Não foi possível adicionar os dados, tente novamente mais tarde.'),
      })
    }
  }

  const handleEditHardware = async (data: IHardwareForm) => {
    if (selectedHardwareId) {
      try {
        let response: any
        if (tabNumber === 0) response = await patchSolarModule(data)
        if (tabNumber === 1) response = await patchSolarInverter(data)
        if (tabNumber === 2) response = await patchSolarTracker(data)
        if (response.success) {
          reset()
          setOpenModal(false)
          setSelectedHardwareId(null)
          ToastNotification({
            id: 'success',
            type: 'success',
            message: t('Os dados foram atualizados com sucesso!'),
          })
        } else {
          ToastNotification({
            id: 'error',
            type: 'error',
            message: t('Não foi possível atualizar os dados, tente novamente mais tarde.'),
          })
        }
      } catch (error) {
        console.error(error)
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possível atualizar os dados, tente novamente mais tarde.'),
        })
      }
    }
  }

  const showAddModalTitle = (tabNumber: number) => {
    if (tabNumber === 0) return t('Criar Novo Módulo')
    if (tabNumber === 1) return t('Criar Novo Inversor')
    if (tabNumber === 2) return t('Criar Novo Tracker')
  }

  const showEditModalTitle = (tabNumber: number) => {
    if (tabNumber === 0) return t('Editar Módulo Existente')
    if (tabNumber === 1) return t('Editar Inversor Existente')
    if (tabNumber === 2) return t('Editar Tracker Existente')
  }

  useEffect(() => {
    if (openModal) loadHardware()
  }, [openModal])

  return (
    <Dialog open={openModal} onClose={() => setOpenModal(false)}>
      <DialogTitle>
        {selectedHardwareId ? showEditModalTitle(tabNumber) : showAddModalTitle(tabNumber)}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(selectedHardwareId ? handleEditHardware : handleAddHardware)}>
          <Controller
            name="manufacturer"
            control={control}
            rules={{
              required: { value: true, message: t('O fabricante é obrigatório.') },
              maxLength: { value: 100, message: t('O fabricante deve ter no máximo 100 caracteres') },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label={t('Fabricante') + '*'}
                fullWidth
                margin="normal"
                InputLabelProps={{ shrink: true }}
                inputProps={{ maxLength: 200 }}
                error={!!errors.manufacturer}
                helperText={errors.manufacturer?.message}
              />
            )}
          />
          <Controller
            name="name"
            control={control}
            rules={{
              required: { value: tabNumber !== 2, message: t('O nome é obrigatório.') },
              maxLength: { value: 100, message: t('O modelo deve ter no máximo 100 caracteres') },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label={`${t('Modelo')}${tabNumber === 2 ? '' : '*'}`}
                fullWidth
                margin="normal"
                InputLabelProps={{ shrink: true }}
                inputProps={{ maxLength: 100 }}
                error={!!errors.name}
                helperText={errors.name?.message}
              />
            )}
          />
          {(tabNumber === 0 || tabNumber === 1) && (
            <Controller
              name={tabNumber === 0 ? 'power' : 'acOutput'}
              control={control}
              rules={{
                pattern: {
                  value: /^\d{1,7}(\.\d{1,3})?$/,
                  message: t(
                    'O número não pode ter mais de 7 dígitos antes do ponto e mais de 3 casas decimais',
                  ),
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  label={tabNumber === 0 ? t('Potência') : t('Potência de Saída')}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    maxLength: 13,
                    step: '1',
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {tabNumber === 0 ? t('W') : t('kW')}
                      </InputAdornment>
                    ),
                  }}
                  error={tabNumber === 0 ? !!errors.power : !!errors.acOutput}
                  helperText={tabNumber === 0 ? errors.power?.message : errors.acOutput?.message}
                />
              )}
            />
          )}
          <DialogActions>
            <Button type="submit" color="primary" variant="contained">
              {selectedHardwareId ? t('Salvar') : t('Criar')}
            </Button>
            <Button
              onClick={() => {
                setOpenModal(false)
                reset()
              }}
              color="primary"
              variant="outlined"
            >
              {t('Cancelar')}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default AddEditHardwareFormDialog
