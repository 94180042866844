import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  Button as MUIButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
  Typography,
  styled,
} from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import * as S from './styles'

import {
  Block,
  Check,
  CloseRounded as CloseRoundedIcon,
  EditLocation,
  Error,
  Pending,
  QueryBuilder,
} from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import DeleteIcon from '@mui/icons-material/Delete'
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore'
import { useTranslation } from 'react-i18next'
import NoImagePlaceholder from '~/assets/images/no-image-placeholder.png'
import ToastNotification from '~/components/ToastNotification'
import { useAuth } from '~/contexts/Auth'
import { deleteComplementaryImage, getAnomalyStatus, putAnomaly } from '~/services/Anomaly'
import {
  AnomalySimpleDTO,
  EAnomalyStatusinteger,
  IResponseAnomalyStatus,
} from '~/services/Anomaly/types'
import { postFile } from '~/services/File'
import { AccessLevel } from '~/utils/General'
import ErrorToast from '~/utils/toastErrorCatch'
import AnomalyImagesModal from './components/AnomalyImages/AnomalyImagesModal'
import ImageModal from './components/ImageModal'

const AddIconButton = styled(IconButton)({
  color: '#000',
  backgroundColor: '#fff',
  borderRadius: '50%',
  padding: '10px',
  boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
  '&:hover': {
    backgroundColor: '#f5f5f5',
  },
  '& .MuiSvgIcon-root': {
    fontSize: '24px',
  },
})

const DashedCircle = styled('div')({
  borderRadius: '50%',
  padding: '10px',
  border: '2px dashed #ccc',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

interface Props {
  anomaly: AnomalySimpleDTO | null
  handleClose: () => void
  onAnomalySaved: () => any
  deleteAnomaly(anomaly: AnomalySimpleDTO): void
  isEditingCoordinates: boolean
  setIsEditingCoordinates: (value: boolean) => void
  setReset: (value: boolean) => void
  handlePrevious: () => void
  handleNext: () => void
}

export const NewSidebarOneAnomaly = (props: Props) => {
  const {
    anomaly,
    handleClose,
    onAnomalySaved,
    deleteAnomaly,
    isEditingCoordinates,
    setIsEditingCoordinates,
    setReset,
    handlePrevious,
    handleNext,
  } = props
  const { t } = useTranslation()
  const { user } = useAuth()

  const [navActive, setNavActive] = useState('anomalia')
  const [statusAnomaly, setStatusAnomaly] = useState<IResponseAnomalyStatus[]>([])
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const [selectedImage, setSelectedImage] = useState<File | null>(null)
  const [fileIdToDelete, setFileIdToDelete] = useState<string | null>(null)
  const [openDialog, setOpenDialog] = useState<boolean | string>(false)
  const [isUploadingImage, setIsUploadingImage] = useState<boolean>(false)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [modalCurrentImageIndex, setModalCurrentImageIndex] = useState<number>(0)

  const openModal = () => {
    setModalOpen(true)
  }

  const toggleCoordinateEditMode = () => {
    setIsEditingCoordinates(!isEditingCoordinates)
  }

  const resetMode = () => {
    setReset(true)
  }

  useEffect(() => {
    loadStatus()
  }, [])

  useEffect(() => {
    if (selectedImage) {
      complementaryImage()
    }
  }, [selectedImage])

  if (!anomaly) return null

  const loadStatus = async () => {
    try {
      const response = await getAnomalyStatus()
      if (response.success) {
        setStatusAnomaly(response.data)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
          errorMessage: response.errorDetails,
          errors: response.errors,
        })
      }
    } catch (error) {
      return (
        <ErrorToast message={t('Não foi possível carregar os status, tente novamente mais tarde.')} />
      )
    }
  }

  const complementaryImage = async () => {
    setIsUploadingImage(true)
    if (!selectedImage) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Selecione um arquivo para fazer upload.'),
      })
      return
    }
    try {
      const formData = new FormData()
      formData.append('Id', anomaly.id)
      formData.append('Type', '1')
      formData.append('File', selectedImage)

      const response = await postFile(formData)
      if (response.success) {
        ToastNotification({
          id: 'success',
          type: 'success',
          message: t('Imagem Enviada com Sucesso.'),
        })
        setIsUploadingImage(false)
        onAnomalySaved()
      } else {
        setIsUploadingImage(false)
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
          errorMessage: response.errorDetails,
          errors: response.errors,
        })
      }
    } catch (error) {
      return (
        <ErrorToast message={t('Não foi possível carregar as imagens, tente novamente mais tarde.')} />
      )
    }
  }

  const handleDeleteImage = async () => {
    try {
      if (anomaly?.id && fileIdToDelete) {
        const response = await deleteComplementaryImage(anomaly.id, fileIdToDelete)
        if (response.success) {
          ToastNotification({
            id: 'success',
            type: 'success',
            message: t('Imagem Removida com Sucesso.'),
          })
          onAnomalySaved()
        } else {
          ToastNotification({
            id: 'error',
            type: 'error',
            message: t('Não foi possível remover a imagem, tente novamente mais tarde.'),
            errorMessage: response.errorDetails,
            errors: response.errors,
          })
        }
      }
    } catch (error) {
      return <ErrorToast message={t('Não foi possível remover a imagem, tente novamente mais tarde.')} />
    }
  }

  const handleAddClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0]
      setSelectedImage(file)
    }
  }

  const handleDeleteAnomaly = (anomay: AnomalySimpleDTO) => {
    deleteAnomaly(anomay)
    handleClose()
  }

  return (
    <S.ContainerSidebar isOpen={!!anomaly} style={{ height: `${window.innerHeight - 98}px` }}>
      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end', flexWrap: 'wrap' }}>
        {user?.accessLevel === AccessLevel.Master && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', alignItems: 'center' }}>
            <Tooltip title={t('Mudar Localização Manualmente')} enterTouchDelay={0}>
              <MUIButton
                color="primary"
                variant="outlined"
                onClick={toggleCoordinateEditMode}
                startIcon={
                  isEditingCoordinates ? (
                    <CircularProgress size={20} />
                  ) : (
                    <EditLocation fontSize="medium" />
                  )
                }
                sx={{
                  fontSize: { xs: '0.7rem', sm: '0.8rem', md: '0.9rem' },
                  padding: '6px 12px',
                  minHeight: '24px',
                  maxHeight: '40px',
                  margin: '16px 16px 0 0',
                }}
              >
                {isEditingCoordinates ? t('Editando') + '...' : t('Editar')}
              </MUIButton>
            </Tooltip>

            <Tooltip title={t('Voltar Localização')} enterTouchDelay={0}>
              <MUIButton
                variant="outlined"
                onClick={resetMode}
                sx={{
                  fontSize: { xs: '0.7rem', sm: '0.8rem', md: '0.9rem' },
                  padding: '6px 12px',
                  minHeight: '24px',
                  maxHeight: '40px',
                  margin: '16px 16px 0 0',
                }}
                startIcon={<SettingsBackupRestoreIcon fontSize="medium" />}
              >
                {t('Restaurar')}
              </MUIButton>
            </Tooltip>

            <Tooltip title={t('A anomalia será deletada')} enterTouchDelay={0}>
              <MUIButton
                color="error"
                size="small"
                variant="outlined"
                onClick={() => handleDeleteAnomaly(anomaly)}
                startIcon={<DeleteIcon />}
                sx={{
                  fontSize: { xs: '0.7rem', sm: '0.8rem', md: '0.9rem' },
                  padding: '6px 12px',
                  minHeight: '24px',
                  maxHeight: '40px',
                  margin: '16px 16px 0 0',
                }}
              >
                {t('Deletar')}
              </MUIButton>
            </Tooltip>

            <IconButton
              onClick={handleClose}
              sx={{
                margin: '16px 16px 0 0',
                color: 'black',
                backgroundColor: 'white',
                '&:hover': {
                  backgroundColor: '#f5f5f5',
                },
                borderRadius: '6px',
                boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
              }}
            >
              <CloseRoundedIcon />
            </IconButton>
          </Box>
        )}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, justifyContent: 'space-between' }}>
        <Box>
          <IconButton
            onClick={handlePrevious}
            sx={{
              color: 'black',
              backgroundColor: 'white',
              '&:hover': {
                backgroundColor: '#f5f5f5',
              },
              margin: '10px',
              borderRadius: '6px',
              padding: '6px 12px',
              boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
            }}
          >
            <ArrowBackIosNewIcon />
          </IconButton>
          <IconButton
            onClick={handleNext}
            sx={{
              color: 'black',
              backgroundColor: 'white',
              '&:hover': {
                backgroundColor: '#f5f5f5',
              },
              borderRadius: '6px',
              padding: '6px 12px',
              boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
            }}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </Box>
        {user?.accessLevel !== AccessLevel.Master && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              onClick={handleClose}
              sx={{
                marginRight: '16px',
                color: 'black',
                backgroundColor: 'white',
                '&:hover': {
                  backgroundColor: '#f5f5f5',
                },
                borderRadius: '6px',
                boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
              }}
            >
              <CloseRoundedIcon />
            </IconButton>
          </Box>
        )}
      </Box>
      <S.TitleWrapper key={anomaly.id}>
        <Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '100%' }}>
          <strong>
            #{anomaly.code} {anomaly.name}
          </strong>
          {anomaly.moduleNumber != null && (
            <Box>
              <Typography variant="body2" style={{ color: 'gray', fontSize: 'small' }}>
                String: {anomaly.stringName}
              </Typography>
              <Typography variant="body2" style={{ color: 'gray', fontSize: 'small' }}>
                Nº {t('Módulo')}: {anomaly.moduleNumber}
              </Typography>
            </Box>
          )}
        </Box>
        <S.ButtonsWrapper></S.ButtonsWrapper>
      </S.TitleWrapper>

      <S.SidebarHeader>
        <S.Flex style={{ display: 'flex', width: '100%', gap: 8, overflowX: 'scroll' }}>
          <S.Flex
            className="anomalias__info"
            style={{ flex: '1', flexDirection: 'column', alignItems: 'center' }}
          >
            <img
              alt="Imagem Térmica"
              src={anomaly.labelledImageUrl !== '' ? anomaly.labelledImageUrl : NoImagePlaceholder}
              style={{
                width: '200px',
                height: '150px',
                borderRadius: '4px',
                cursor: 'pointer',
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  openModal()
                }
              }}
              onClick={() => {
                openModal()
                setModalCurrentImageIndex(0)
              }}
            />
            <S.Text className="anomalias__card-tipoAnomalia">{t('Térmica')}</S.Text>
          </S.Flex>
          <S.Flex
            className="anomalias__info"
            style={{ flex: '1', flexDirection: 'column', alignItems: 'center' }}
          >
            <img
              alt="Imagem RGB"
              src={anomaly.rgbImageUrl !== '' ? anomaly.rgbImageUrl : NoImagePlaceholder}
              style={{
                width: '200px',
                height: '150px',
                borderRadius: '4px',
                cursor: 'pointer',
              }}
              onClick={() => {
                openModal()
                setModalCurrentImageIndex(1)
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter' || event.key === ' ') {
                  openModal()
                }
              }}
            />
            <S.Text className="anomalias__card-tipoAnomalia">{t('RGB')}</S.Text>
          </S.Flex>
          {anomaly.complementaryImageUrls.length > 0 &&
            anomaly.complementaryImageUrls.map((imageUrl: any, index: number) => {
              return (
                <S.Flex
                  key={imageUrl.fileId}
                  className="anomalias__info"
                  style={{
                    flex: '1',
                    flexDirection: 'column',
                    alignItems: 'center',
                    position: 'relative',
                  }}
                >
                  {user?.accessLevel !== 0 && (
                    <Tooltip title={t('Remover imagem')}>
                      <button
                        onClick={() => {
                          setFileIdToDelete(imageUrl.fileId)
                          setOpenDialog(true)
                        }}
                        style={{
                          position: 'absolute',
                          top: '5px',
                          right: '5px',
                          backgroundColor: 'gray',
                          color: 'white',
                          border: 'none',
                          borderRadius: '50%',
                          width: '24px',
                          height: '24px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                      >
                        X
                      </button>
                    </Tooltip>
                  )}
                  <img
                    alt="Imagem Selecionada"
                    src={imageUrl.url !== '' ? imageUrl.url : NoImagePlaceholder}
                    style={{ width: '200px', height: '150px', borderRadius: '4px', cursor: 'pointer' }}
                    onClick={() => {
                      openModal()
                      setModalCurrentImageIndex(index + 2)
                    }}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter' || event.key === ' ') {
                        openModal()
                      }
                    }}
                  />
                  <S.Text className="anomalias__card-tipoAnomalia">{t('Enviada')}</S.Text>
                </S.Flex>
              )
            })}
          {anomaly.complementaryImageUrls.length <= 2 && (
            <div
              style={{
                flex: '1',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                position: 'relative',
                justifyContent: 'center',
                padding: '0 50px',
              }}
            >
              {user?.accessLevel !== 0 &&
                (isUploadingImage ? (
                  <DashedCircle>
                    <CircularProgress />
                  </DashedCircle>
                ) : (
                  <DashedCircle>
                    <Tooltip title={t('Adicionar imagem')}>
                      <AddIconButton onClick={handleAddClick}>
                        <AddIcon />
                      </AddIconButton>
                    </Tooltip>
                    <input
                      ref={fileInputRef}
                      type="file"
                      accept="image/jpeg, image/png"
                      onChange={(event) => {
                        handleFileChange(event)
                      }}
                      style={{ display: 'none' }}
                    />
                  </DashedCircle>
                ))}
              {user?.accessLevel !== 0 && (
                <S.Text
                  className="anomalias__card-tipoAnomalia"
                  style={{ marginTop: '10px', textAlign: 'center' }}
                >
                  {t('Enviar Imagem Complementar')}
                </S.Text>
              )}
            </div>
          )}
        </S.Flex>
      </S.SidebarHeader>
      <S.SidebarNav>
        <S.Text
          className={`nav__item ${navActive === 'anomalia' ? ' active' : ''}`}
          onClick={() => setNavActive('anomalia')}
        >
          {t('Anomalia')}
        </S.Text>
        {user?.accessLevel !== 0 && (
          <S.Text
            className={`nav__item ${navActive === 'detalhes' ? ' active' : ''}`}
            onClick={() => setNavActive('detalhes')}
          >
            {t('Painel Detalhes')}
          </S.Text>
        )}
      </S.SidebarNav>
      <RenderNav
        anomaly={anomaly}
        statusAnomalias={statusAnomaly}
        navActive={navActive}
        handleClose={handleClose}
        onAnomalySaved={onAnomalySaved}
      />
      <ImageModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        thermalImageUrl={anomaly.labelledImageUrl ?? ''}
        rgbImageUrl={anomaly.rgbImageUrl ?? ''}
      />
      <AnomalyImagesModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        rgbImageUrl={anomaly.rgbImageUrl ?? ''}
        thermalImageUrl={anomaly.labelledImageUrl ?? ''}
        complementaryImageUrls={anomaly.complementaryImageUrls ?? ''}
        modalCurrentImageIndex={modalCurrentImageIndex}
      />
      <Dialog open={!!openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle id="alert-dialog-title">{t('Tem certeza?')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('Deseja realmente excluir essa imagem complementar?')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleDeleteImage()
              setOpenDialog(false)
            }}
            variant="contained"
          >
            {t('Sim')}
          </Button>
          <Button onClick={() => setOpenDialog(false)} variant="outlined" autoFocus>
            {t('Não')}
          </Button>
        </DialogActions>
      </Dialog>
    </S.ContainerSidebar>
  )
}

const RenderNav = ({
  anomaly,
  navActive,
  statusAnomalias,
  handleClose,
  onAnomalySaved,
}: {
  anomaly: AnomalySimpleDTO
  navActive: string
  statusAnomalias: IResponseAnomalyStatus[]
  handleClose?: () => void
  onAnomalySaved: () => Promise<void>
}) => {
  const { t } = useTranslation()
  const { user } = useAuth()

  const [comment, setComment] = useState(anomaly.comment)

  const [statusSelected, setStatusSelected] = useState<EAnomalyStatusinteger | ''>(anomaly.status)

  useEffect(() => {
    setStatusSelected(anomaly.status || '')
  }, [anomaly.status])

  useEffect(() => {
    if (anomaly.comment) {
      setComment(anomaly.comment)
    } else {
      setComment('')
    }
  }, [anomaly.comment])

  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value)
  }

  const handleChange = (event: SelectChangeEvent<string>) => {
    const newValue = event.target.value
    if (newValue !== '') {
      setStatusSelected(Number(newValue) as EAnomalyStatusinteger)
    }
  }

  useEffect(() => {
    if (anomaly) {
      const validStatus = statusAnomalias.find((status) => status.id === anomaly.status)
      if (validStatus) {
        setStatusSelected(anomaly.status)
      } else {
        setStatusSelected('')
      }
    }
  }, [anomaly, statusAnomalias])

  const dadosStatus = statusAnomalias.map((statusAnomalia) => {
    let icon
    switch (statusAnomalia.id) {
      case 0:
        icon = <Pending className="icon" />
        break
      case 1:
        icon = <Check className="icon" />
        break
      case 2:
        icon = <QueryBuilder className="icon" />
        break
      case 3:
        icon = <Block className="icon" />
        break
      case 4:
        icon = <Error className="icon" />
        break
      default:
        icon = null
    }

    return {
      value: statusAnomalia.id,
      status: statusAnomalia.status ?? 'defaultStatus',
      icon: icon,
    }
  })

  const renderStatusText = (statusId: number): string => {
    const statusObj = statusAnomalias.find((status) => status.id === statusId)
    return statusObj ? statusObj.status ?? 'Desconhecido' : 'Desconhecido'
  }

  const handleAnomalyUpdate = async () => {
    try {
      const response = await putAnomaly({
        id: anomaly.id,
        latitude: anomaly.latitude.toString(),
        longitude: anomaly.longitude.toString(),
        status: Number(statusSelected),
        comment: comment,
      })
      if (response.success) {
        ToastNotification({
          id: 'success',
          type: 'success',
          message: t('Anomalia alterada com sucesso.'),
        })
        onAnomalySaved()
        if (handleClose) {
          handleClose()
        }
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
          errorMessage: response.errorDetails,
          errors: response.errors,
        })
      }
    } catch (error) {
      return (
        <ErrorToast message={t('Não foi possível editar a anomalia, tente novamente mais tarde.')} />
      )
    }
  }

  if (navActive === 'anomalia') {
    return (
      <S.BoxNav>
        <S.TableOneAnomalia>
          <S.Flex className="table__indice">
            <S.Text className="table__itens">{t('Temperatura Delta (ΔT)')}</S.Text>
            <S.Text className="table__itens">{t('Temperatura máxima')}</S.Text>
            <S.Text className="table__itens">{t('Temperatura mínima')}</S.Text>
            <S.Text className="table__itens">{t('Temperatura média')}</S.Text>
            <S.Text className="table__itens">{t('Irradiância')}</S.Text>
            <S.Text className="table__itens">{t('Status')}</S.Text>
            <S.Text className="table__itens">{t('Comentários')}</S.Text>
          </S.Flex>
          <S.Flex className="table__item">
            <S.Text className="table__itens right">{anomaly.temperatureDelta} °C</S.Text>
            <S.Text className="table__itens right">{anomaly.temperatureHighest} °C</S.Text>
            <S.Text className="table__itens right">{anomaly.temperatureLowest} °C</S.Text>
            <S.Text className="table__itens right">{anomaly.temperatureMean} °C</S.Text>
            <S.Text className="table__itens right">{anomaly.irradiance?.toFixed(2)}</S.Text>
            <S.Text className="table__itens right">{t(renderStatusText(anomaly.status))}</S.Text>

            <S.Text className="table__itens right">{anomaly.comment}</S.Text>
          </S.Flex>
        </S.TableOneAnomalia>

        <S.TableOneAnomalia>
          <S.Flex className="table__indice">
            <S.Text className="table__itens">{t('Latitude')}</S.Text>
            <S.Text className="table__itens">{t('Longitude')}</S.Text>
          </S.Flex>
          <S.Flex className="table__item">
            <S.Text className="table__itens right">{Number(anomaly.latitude)}</S.Text>
            <S.Text className="table__itens right">{Number(anomaly.longitude)}</S.Text>
          </S.Flex>
        </S.TableOneAnomalia>
      </S.BoxNav>
    )
  } else if (navActive === 'detalhes') {
    return (
      <S.BoxNav>
        <S.Label>
          <FormControl style={{ display: 'flex', width: '200px' }}>
            <InputLabel id="demo-simple-select-helper-label">{t('Status')}</InputLabel>
            <Select
              MenuProps={{
                MenuListProps: {
                  sx: { maxHeight: '250px', overflowY: 'auto' },
                },
              }}
              label={t('Status')}
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={statusSelected.toString()}
              onChange={handleChange}
            >
              {dadosStatus.map((item) => (
                <MenuItem key={item.value} value={item.value.toString()}>
                  <S.BoxMenuItem>
                    {item.icon}
                    {t(item.status ?? 'defaultStatus')}
                  </S.BoxMenuItem>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </S.Label>
        <TextField
          label={t('Comentários')}
          multiline
          rows={4}
          variant="outlined"
          style={{ width: '100%', margin: '10px 0' }}
          value={comment}
          onChange={handleCommentChange}
        />
        {user?.accessLevel !== 0 && <S.Button onClick={handleAnomalyUpdate}>{t('Salvar')}</S.Button>}
      </S.BoxNav>
    )
  } else {
    return <></>
  }
}
