import { deleteJson, getJson, postJson, putJson } from '../RequestService'
import { IRequestSolarModule, IResponseSolarModule } from './types'

const defaultRoot = 'solarmodule'

export const getAllSolarModule = async (data: IRequestSolarModule) => {
  let url = `${defaultRoot}?`
  if (data.filter) url += `Filter=${data.filter}`
  if (data.onlyActive) url += `&OnlyActive=${data.onlyActive}`
  if (data.pageNumber) url += `&PageNumber=${data.pageNumber}`
  if (data.pageSize) url += `&PageSize=${data.pageSize}`
  return await getJson<IResponseSolarModule[]>(url)
}

export const getSolarModule = async (data: IRequestSolarModule) => {
  let url = `${defaultRoot}`
  if (data.id) url += `/${data.id}`
  return await getJson<IResponseSolarModule[]>(url)
}

export const postSolarModule = async (data: IResponseSolarModule) => {
  let url = `${defaultRoot}?`
  return await postJson<IResponseSolarModule, IResponseSolarModule>(url, data)
}

export const patchSolarModule = async (data: IResponseSolarModule) => {
  let url = `${defaultRoot}?`
  return await putJson<IResponseSolarModule, IResponseSolarModule>(url, data)
}

export const deleteSolarModule = async (data: IRequestSolarModule) => {
  let url = `${defaultRoot}?`
  url += `id=${data.id}`
  return await deleteJson<IRequestSolarModule, IResponseSolarModule>(url, data)
}
