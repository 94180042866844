import { deleteJson, getJson, postJson, putJson } from '../RequestService'
import { IRequestSolarTracker, IResponseSolarTracker } from './types'

const defaultRoot = 'solartracker'

export const getAllSolarTracker = async (data: IRequestSolarTracker) => {
  let url = `${defaultRoot}?`
  if (data.filter) url += `Filter=${data.filter}`
  if (data.onlyActive) url += `&OnlyActive=${data.onlyActive}`
  if (data.pageNumber) url += `&PageNumber=${data.pageNumber}`
  if (data.pageSize) url += `&PageSize=${data.pageSize}`
  return await getJson<IResponseSolarTracker[]>(url)
}

export const getSolarTracker = async (data: IRequestSolarTracker) => {
  let url = `${defaultRoot}`
  if (data.id) url += `/${data.id}`
  return await getJson<IResponseSolarTracker[]>(url)
}

export const postSolarTracker = async (data: IResponseSolarTracker) => {
  let url = `${defaultRoot}?`
  return await postJson<IResponseSolarTracker, IResponseSolarTracker>(url, data)
}

export const patchSolarTracker = async (data: IResponseSolarTracker) => {
  let url = `${defaultRoot}?`
  return await putJson<IResponseSolarTracker, IResponseSolarTracker>(url, data)
}

export const deleteSolarTracker = async (data: IRequestSolarTracker) => {
  let url = `${defaultRoot}?`
  url += `id=${data.id}`
  return await deleteJson<IRequestSolarTracker, IResponseSolarTracker>(url, data)
}
